import { ComboBox, Dropdown, IComboBoxOption, IconButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import {
    arrayTypeFilters,
    desktopTCNS,
    desktopVersion,
    experienceBuild,
    webRWC,
    GUIDTypeFilters,
    osPlatform,
    GUIDAndEmailTypeFilters
} from '../configs/defaults';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { operatorInputStyle, pairInputStyle, removeIcon } from '../styles/StartFlightFormStyle';
import { BaseFilters, FilterData, FilterOperation, FilterSchema } from '../types/Types';
import { convertFiltersListToDict, isValidClientVersion, validateListOfTypes } from '../utilities/FFv2Utils';

type FiltersInputProps = {
    client: string;
    kind: 'base' | 'user';
    filterSchema: FilterSchema[];
    reset: boolean;
    addFilters: (filters: BaseFilters) => void;
    deleteFilter: (filterName: string) => void;
    defaultFilters?: FilterData[];
    required?: boolean;
};

/**
 *
 * Renders the component for Filters Input.
 *
 * @param props - The props needed to update and delete filters in the form state, and reset input.
 * @returns The JSX element representing the filters input component.
 */
const FiltersInput: React.FC<FiltersInputProps> = (props) => {
    // ========================= State =========================
    const { client, kind, reset } = props;

    const defaultFilters = props.defaultFilters?.length
        ? props.defaultFilters
        : [{ name: '', value: '', operation: FilterOperation.Equal }];
    const [filtersOnPage, setFiltersOnPage] = useState<FilterData[]>(defaultFilters);

    const options = (() => {
        const filters = props.filterSchema.filter((item) => item.key !== (client === webRWC ? experienceBuild : desktopVersion));

        return filters.map((item) => {
            return {
                key: item.key,
                text: item.key,
                disabled: filtersOnPage.map((row) => row.name).includes(item.key)
            };
        });
    })();

    const operationOptions = (filterName: string) => {
        const filterSchema = props.filterSchema.find((item) => item.key === filterName);

        const opOptions = Object.values(FilterOperation)
            .filter((operator) => filterSchema?.operations.includes(operator))
            .map((operator) => ({ key: operator, text: operator }) as IComboBoxOption);

        return opOptions.length > 0 ? opOptions : [{ key: FilterOperation.Equal, text: FilterOperation.Equal }];
    };

    // ========================= Hooks =========================
    useEffect(() => {
        setFiltersOnPage(defaultFilters);
    }, [reset]);

    useEffect(() => {
        if (kind !== 'base') {
            return;
        }

        let updatedFilters = filtersOnPage.filter((filter) => filter.name !== (client === webRWC ? experienceBuild : desktopVersion));
        if (updatedFilters.length === 0) {
            updatedFilters = [{ name: '', value: '', operation: FilterOperation.Equal }];
        }

        setFiltersOnPage(updatedFilters);
    }, [client]);

    // ========================= Event handlers =========================
    const handleFilterOperationChange = (index: number, name: string, operation: string) => {
        const updatedFiltersOnPage = [...filtersOnPage];
        const prevFilter = updatedFiltersOnPage[index];
        updatedFiltersOnPage[index] = { name: name, value: prevFilter.value, operation: operation as FilterOperation };

        const updatedFiltersToSubmit = updatedFiltersOnPage.filter((filter) => filter.name !== '' && filter.value !== '');

        setFiltersOnPage(updatedFiltersOnPage);
        props.addFilters(convertFiltersListToDict(updatedFiltersToSubmit));
    };

    const handleFilterInputChange = (index: number, name: string, value: string, multiSelect?: boolean) => {
        const updatedFiltersOnPage = [...filtersOnPage];
        const prevFilter = updatedFiltersOnPage[index];
        updatedFiltersOnPage[index] = { name: name, value: value, operation: prevFilter.operation };

        const updatedFiltersToSubmit = updatedFiltersOnPage.filter((filter) => filter.name !== '' && filter.value !== '');

        // if the filter name is changed, delete the previous filter
        if (prevFilter.name !== name) {
            props.deleteFilter(prevFilter.name);
            updatedFiltersOnPage[index].operation = FilterOperation.Equal;
            updatedFiltersOnPage[index].value = '';
            updatedFiltersToSubmit.filter((filter) => filter.name !== prevFilter.name);
        }

        setFiltersOnPage(updatedFiltersOnPage);

        if (multiSelect && !value) {
            // if the value is empty, remove the filter from formData
            props.deleteFilter(name);
        } else {
            props.addFilters(convertFiltersListToDict(updatedFiltersToSubmit));
        }
    };

    const handleAddFilter = () => {
        setFiltersOnPage([...filtersOnPage, { name: '', value: '', operation: FilterOperation.Equal }]);
        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:AddFilterEvent' });
    };

    const handleRemoveFilter = (name: string) => {
        const updatedFiltersOnPage = filtersOnPage.filter((filter) => filter.name !== name);

        setFiltersOnPage(
            updatedFiltersOnPage.length === 0 ? [{ name: '', value: '', operation: FilterOperation.Equal }] : updatedFiltersOnPage
        );
        props.deleteFilter(name);

        appInsightsClient.logEvent({ name: 'FFV2:StartFlight:RemoveFilterEvent', properties: { filterName: name } });
    };

    // ========================= Helper Functions =========================
    const getValueErrorMessage = (filterName: string, value: string): string | undefined => {
        if (filterName === 'DesktopVersion' && (!value || !isValidClientVersion(desktopTCNS, value))) {
            appInsightsClient.logEvent({ name: 'FFV2:StartFlight:InvalidDesktopVersionError' });
            return 'Invalid desktop version, the format is 11111.111.1111.1111';
        }

        if (arrayTypeFilters.includes(filterName)) {
            if (GUIDTypeFilters.includes(filterName) && (!value || !validateListOfTypes(value, ['guid']))) {
                appInsightsClient.logEvent({ name: `FFV2:StartFlight:Invalid${filterName}` });
                return `${filterName} filter must be a list of valid GUID(s) separated by ','`;
            }
            if (GUIDAndEmailTypeFilters.includes(filterName) && (!value || !validateListOfTypes(value, ['guid', 'email']))) {
                appInsightsClient.logEvent({ name: `FFV2:StartFlight:Invalid${filterName}` });
                return `${filterName} filter must be a list of valid GUID(s) and/or email(s) separated by ','`;
            }
        }

        if (filterName.length > 0 && !value) return 'Fill in a filter value';

        return '';
    };

    const getNameErrorMessage = (index: number): string | undefined => {
        if (props.required && filtersOnPage.every((filter) => filter.name === '') && index === 0) {
            return 'At least one filter is required';
        }

        return '';
    };

    const getFilterType = (filterName: string): string => {
        const target = props.filterSchema.filter((item) => item.key === filterName);
        if (target.length > 0 && target[0] !== undefined) {
            return target[0].type;
        }
        return '';
    };

    const getFilterOptions = (filterName: string): unknown[] | undefined => {
        const target = props.filterSchema.filter((item) => item.key === filterName);
        if (target.length > 0 && target[0] !== undefined) {
            return target[0].options;
        }
        return undefined as unknown[] | undefined;
    };

    const FilterDataInput = (filter: FilterData, index: number) => {
        const type = getFilterType(filter.name);
        const targetOptions = getFilterOptions(filter.name);

        switch (true) {
            case type === 'boolean': {
                return (
                    <ComboBox
                        selectedKey={filter.value}
                        styles={pairInputStyle}
                        placeholder="Select boolean"
                        options={[
                            { key: 'true', text: 'true' },
                            { key: 'false', text: 'false' }
                        ]}
                        onChange={(event, option, optionIndex, value) => {
                            if (value !== undefined) {
                                handleFilterInputChange(index, filter.name, value);
                            }
                        }}
                        errorMessage={getValueErrorMessage(filter.name, filter.value)}
                    />
                );
            }
            case type === 'array' && !!targetOptions: {
                const comboBoxOptions = targetOptions
                    ? targetOptions.map((option) => ({ key: option, text: option }) as IComboBoxOption)
                    : [];
                if (filter.name === osPlatform) {
                    comboBoxOptions.map((option) => {
                        if (option.key === 'windows') {
                            option.disabled = false;
                        } else if (option.key === 'mac') {
                            option.disabled = true;
                        } else {
                            option.disabled = true;
                        }
                    });
                }
                return (
                    <ComboBox
                        selectedKey={filter.value.split(',').map((value) => value.trim())}
                        styles={pairInputStyle}
                        placeholder="Select at least one"
                        multiSelect
                        options={comboBoxOptions}
                        onChange={(event, option) => {
                            if (option) {
                                const selected = option?.selected;
                                const prevSelected = filter.value ? filter.value.split(',').map((value) => value.trim()) : [];
                                const filterValue = (
                                    selected ? [...prevSelected, option.key as string] : prevSelected.filter((k) => k !== option.key)
                                ).join(',');
                                handleFilterInputChange(index, filter.name, filterValue, true);
                            }
                        }}
                        errorMessage={getValueErrorMessage(filter.name, filter.value)}
                    />
                );
            }
            default: {
                return (
                    <TextField
                        value={filter.value}
                        placeholder="Filter value"
                        styles={pairInputStyle}
                        validateOnLoad
                        validateOnFocusOut
                        errorMessage={getValueErrorMessage(filter.name, filter.value)}
                        onChange={(event, newValue) => {
                            if (newValue !== undefined) {
                                handleFilterInputChange(index, filter.name, newValue);
                            }
                        }}
                    />
                );
            }
        }
    };

    // ========================= Render =========================
    return (
        <Stack tokens={gapStackTokensMedium}>
            <Stack tokens={gapStackTokensMedium}>
                {filtersOnPage.map((filter, index) => (
                    <Stack horizontal key={index} tokens={gapStackTokensMedium}>
                        <ComboBox
                            selectedKey={filter.name}
                            placeholder="Select filter"
                            styles={pairInputStyle}
                            options={options}
                            onChange={(event, option, optionIndex, name) => {
                                if (name !== undefined) {
                                    handleFilterInputChange(index, name, '');
                                }
                            }}
                            errorMessage={getNameErrorMessage(index)}
                        />
                        <Dropdown
                            id={`${filter.name}`}
                            selectedKey={filter.operation}
                            styles={operatorInputStyle}
                            placeholder="="
                            options={operationOptions(filter.name)}
                            onChange={(event, operation) => {
                                if (operation !== undefined) {
                                    handleFilterOperationChange(index, filter.name, operation.text);
                                }
                            }}
                        />
                        {FilterDataInput(filter, index)}
                        {(filtersOnPage.length > 1 || filtersOnPage[0].name !== '' || filtersOnPage[0].value !== '') && (
                            <IconButton iconProps={removeIcon} onClick={() => handleRemoveFilter(filter.name)} />
                        )}
                    </Stack>
                ))}
                <PrimaryButton styles={pairInputStyle} onClick={handleAddFilter}>
                    + Add Filter
                </PrimaryButton>
            </Stack>
        </Stack>
    );
};

export default FiltersInput;
