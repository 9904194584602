import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import React from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { ProcessedFlightRollout } from '../types/Types';

type ResumeFlightDialogProps = {
    isOpen: boolean;
    selectedFlight: ProcessedFlightRollout | undefined;
    onDismiss: () => void;
    updateFlight: (flightId: string) => void;
};

/**
 * Renders a dialog component for resuming a paused flight.
 *
 * @param {ResumeFlightDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ResumeFlightDialog: React.FC<ResumeFlightDialogProps> = (props) => {
    const { isOpen, selectedFlight: flight, onDismiss, updateFlight } = props;

    const dialogContentProps = {
        type: DialogType.normal,
        title: `Resume Flight ${flight?.id}`,
        closeButtonAriaLabel: 'Close',
        subText:
            'Resuming the flight will restart the ECS rollout from the last completed stage. Are you sure you want to resume this flight?'
    };

    const handleConfirm = async () => {
        if (!flight) return;
        await new FeatureFlightService().updateManuallyPaused(flight.id, false).then((_) => {
            updateFlight(flight.id);
            onDismiss();
        });
    };

    return (
        <Dialog hidden={!isOpen || !flight} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton onClick={handleConfirm} text="Resume" />
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default ResumeFlightDialog;
