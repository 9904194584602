import { Icon, IconButton, Link, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import React from 'react';

import { Card } from '../../../components/Card';
import FeatureFlightService from '../../../services/featureFlight.service';
import { extraSmallIconClass, gapStackTokensLarge, gapStackTokensMedium } from '../styles/FFv2Style';
import { ProcessedFlightRollout } from '../types/Types';

type FlightTitleCardProps = {
    flight: ProcessedFlightRollout;
    updateSubscribed: (isSubscribed: boolean) => void;
};

/**
 * Displays a card with flight title info.
 *
 * @param props The props for the component.
 * @returns The flight title card.
 */
const FlightTitleCard: React.FC<FlightTitleCardProps> = (props) => {
    const flightUrl = window.location.origin + '/featureFlight/' + props.flight.rolloutId;

    const subscribe = () => {
        new FeatureFlightService().subscribe(props.flight.id).then(() => {
            props.updateSubscribed(true);
        });
    };

    const unsubscribe = () => {
        new FeatureFlightService().unsubscribe(props.flight.id).then(() => {
            props.updateSubscribed(false);
        });
    };

    return (
        <Card styles={{ root: { width: '75%' } }}>
            <Stack tokens={gapStackTokensMedium}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon iconName="AirplaneSolid" className={extraSmallIconClass} /> &nbsp;
                    <Text variant="smallPlus">
                        <Link href={flightUrl} target="_blank">
                            FLIGHT {props.flight.rolloutId}
                        </Link>
                    </Text>
                </span>
                <Stack horizontal tokens={gapStackTokensLarge}>
                    <Text variant="large">{props.flight.rolloutId}</Text>
                    <Text variant="large">{props.flight.rolloutName}</Text>
                    <IconButton
                        iconProps={{ iconName: props.flight.isSubscribed ? 'FavoriteStarFill' : 'FavoriteStar' }}
                        title="Favorite"
                        ariaLabel="Favorite"
                        styles={{
                            root: {
                                fontSize: '30px',
                                paddingBottom: '6px'
                            }
                        }}
                        onClick={() => (props.flight.isSubscribed ? unsubscribe() : subscribe())}
                    />
                </Stack>
                <Persona
                    imageInitials={props.flight.createdBy.displayName
                        .split(' ')
                        .map((i) => i.charAt(0))
                        .join('')}
                    text={props.flight.createdBy.displayName}
                    size={PersonaSize.size24}
                />
            </Stack>
        </Card>
    );
};

export default FlightTitleCard;
