import { SKYNET_BASE_URL } from '../configs/env.config';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import ApiResponseError from './models/error/ApiResponseError';

interface RingInsights {
    completedOn: string;
    buildNumber: string;
    latestCommit: string;
    buildUrl: string;
    changesUrl: string;
    ringBlockers: RingBlockerData[];
    desktopData: DesktopData;
    webClientData: WebClientData;
    teamsVersion: string;
}

interface RingBlockerData {
    id: number;
    url: string;
    title: string;
}

interface DesktopData {
    teamsVersion: VersionData;
    electronVersion: VersionData;
    slimcoreVersion: VersionData;
    tsCallingVersion: string;
}

interface VersionData {
    stable: string;
    experimental: string;
}

interface WebClientData {
    orbitalFiles: OrbitalData;
    orbitalMultiWindow: OrbitalData;
}

interface OrbitalData {
    commit: string;
    version: string;
    buildUrl: string;
    changesUrl: string;
}

/**
 * Service for retrieving ring insights.
 */
class RingInsightsService {
    apiService: ApiService;
    shiproomBaseUri: string;

    /**
     * Constructs a new instance of the RingInsightsService class.
     */
    constructor() {
        this.apiService = ApiManager.getApiService();
        this.shiproomBaseUri = `${SKYNET_BASE_URL}/api/dashboard/shiproomReport`;
    }

    getRingInsights = (
        client: string,
        os: string,
        environment: string,
        experience: string,
        cloud: string,
        ring = 'Ring0'
    ): Promise<RingInsights> => {
        const params = new URLSearchParams({
            clientType: client,
            os: os,
            environment: environment,
            experience: experience,
            cloud: cloud,
            ring: ring
        });

        try {
            return this.apiService.get<RingInsights>(`${this.shiproomBaseUri}/getLatestRingInsightsForCloudRing?${params.toString()}`);
        } catch (error) {
            let errorMessage = 'Failed to get ring insights';

            if (error instanceof ApiResponseError) {
                if (error.statusCode === 403) {
                    errorMessage = 'Unable to connect to server. Please try again later.';
                } else if (error.errorDetail) {
                    errorMessage = error.errorDetail;
                }
            }

            throw new Error(errorMessage);
        }
    };
}

export default RingInsightsService;
