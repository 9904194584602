import { ITextField, TextField } from '@fluentui/react';
import React, { createRef, useEffect, useState } from 'react';

import { textFieldStyles } from '../styles/StartFlightFormStyle';

type RolloutNameInputProps = {
    reset: boolean;
    showAllErrors: boolean;
    update: (name: string) => void;
    defaultValue?: string;
};

/**
 * Renders an input field for entering a rollout name.
 *
 * @param props - The props containing rolloutName, setRolloutName, and setFormData.
 * @returns The JSX element representing the rollout name input.
 */
const RolloutNameInput: React.FC<RolloutNameInputProps> = (props) => {
    // ========================= State =========================
    const { reset, showAllErrors } = props;
    const defaultValue = props.defaultValue && props.defaultValue.length > 4 ? props.defaultValue.substring(4) : '';
    const [name, setName] = useState<string>(defaultValue);
    const textFieldRef = createRef<ITextField>();

    // ========================= Hooks =========================
    useEffect(() => {
        if (showAllErrors && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [showAllErrors]);

    useEffect(() => {
        setName(defaultValue);
    }, [reset]);

    useEffect(() => {
        if (showAllErrors && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [showAllErrors]);

    const handleTextInputChange = (value: string) => {
        setName(value);
        props.update('[FF]' + value);
    };

    const getRolloutNameErrorMessage = (value: string) => {
        // test value against this regex  /^[a-zA-Z0-9 ]*$/ to make sure it only contains alphanumeric characters and spaces
        const regex = /^[a-zA-Z0-9 ]*$/;

        if (value.length < 3 || value.length > 100) {
            return 'Rollout name must be between 3 and 100 characters (excluding prefix)';
        } else if (!regex.test(value)) {
            return 'Rollout name must only contain alphanumeric characters and spaces';
        } else {
            return '';
        }
    };

    // ========================= Render =========================
    return (
        <TextField
            prefix="[FF]"
            styles={textFieldStyles}
            id="rolloutName"
            placeholder="Rollout Name"
            value={name}
            onGetErrorMessage={getRolloutNameErrorMessage}
            componentRef={textFieldRef}
            validateOnLoad={false}
            validateOnFocusIn
            validateOnFocusOut
            deferredValidationTime={500}
            onChange={(_, value) => {
                if (value !== undefined) {
                    handleTextInputChange(value);
                }
            }}
        />
    );
};

export default RolloutNameInput;
