import { Dropdown, IDropdownOption, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { disabledExperiences, meetingRoom, reactWebClient } from '../configs/defaults';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { dropdownStyles } from '../styles/StartFlightFormStyle';

type ExperienceDropdownProps = {
    isOpen: boolean;
    reset: boolean;
    showAllErrors: boolean;
    experienceOptions: string[];
    update: (value?: string[]) => void;
    defaultValue?: string[];
};

/**
 * Renders a combo box to select experience options.
 *
 * @param props - The props containing experienceOptions and isExperienceOptionsEnable.
 * @returns The JSX element representing the experience combo box.
 */
const ExperienceDropdown: React.FC<ExperienceDropdownProps> = (props) => {
    // ================= State =================
    const { isOpen, reset, experienceOptions } = props;
    const defaultValue = props.defaultValue ?? [meetingRoom, reactWebClient];
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>(defaultValue);
    const options = experienceOptions.map((key) => ({
        key: key,
        text: key,
        disabled: disabledExperiences.includes(key)
    }));

    // ========================= Hooks =========================
    useEffect(() => {
        setSelectedKeys(defaultValue);
        props.update(defaultValue);
    }, [isOpen, reset]);

    const onChangeInput = (_?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        if (item) {
            const key = item.key.toString();
            let newSelectedKeys: string[] = [];
            if (item.selected) {
                const keysToAdd = key === reactWebClient ? [meetingRoom, key] : [key];
                newSelectedKeys = [...new Set([...selectedKeys, ...keysToAdd])].sort();
            } else {
                newSelectedKeys = selectedKeys.filter((selectedKey) => selectedKey !== key);
            }
            setSelectedKeys(newSelectedKeys);
            props.update(newSelectedKeys);
            appInsightsClient.logEvent({
                name: `FFV2:StartFlight:ExperienceDropdownChange`,
                properties: { value: item, experiencesAfterChange: newSelectedKeys.join(',') }
            });
        }
    };

    return (
        <Stack tokens={gapStackTokensMedium}>
            <MessageBar delayedRender={false} messageBarType={MessageBarType.info} styles={{ root: { maxWidth: 800 } }}>
                By default, releasing to react-web-client also releases to meeting-room. Please only remove meeting-room if your feature is
                not intended for meeting-room and you have confirmed this with the meeting-room team.
            </MessageBar>
            <Dropdown
                options={options}
                selectedKeys={selectedKeys}
                styles={dropdownStyles}
                multiSelect
                onChange={onChangeInput}
                errorMessage={props.showAllErrors && selectedKeys.length === 0 ? 'Select at least one experience' : undefined}
            />
        </Stack>
    );
};

export default ExperienceDropdown;
