import React from 'react';

import { desktopTCNS, desktopTCNSDisplayName, webRWC, webRWCDisplayName } from '../configs/defaults';

import Options from './Options';

type ClientOptionsProps = {
    reset: boolean;
    update?: (client: string) => void;
    defaultValue?: string;
};

/**
 * Renders client options as a choice group and updates experience options accordingly.
 *
 * @param props - The props containing experienceOptions, setExperienceOptions, setIsExperienceOptionsEnable, and setFormData.
 * @returns The JSX element representing the client options choice group.
 */
const ClientOptions: React.FC<ClientOptionsProps> = (props) => {
    return (
        <Options
            {...props}
            defaultValue={props.defaultValue ?? webRWC}
            options={[
                { key: webRWC, text: webRWCDisplayName },
                { key: desktopTCNS, text: desktopTCNSDisplayName }
            ]}
        />
    );
};

export default ClientOptions;
