import { Spinner, SpinnerSize, DetailsList, SelectionMode, DetailsListLayoutMode, IColumn } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import './RingInsights.css';
import { Card, CardHeader } from '../../components/Card';
import RingInsightsService from '../../services/ringInsights.service';

import { rowsDefinition, IRow, columnsDefinition } from './types';

interface ClientSpecificInsightsProps {
    client: string;
    os: string;
    env: string;
    ring: string;
    header: string;
}

/**
 * Client-specific insights component.
 *
 * @param {ClientSpecificInsightsProps} props - The component props.
 * @returns {React.FC} The client-specific insights component.
 */
const ClientSpecificInsights: React.FC<ClientSpecificInsightsProps> = (props: ClientSpecificInsightsProps) => {
    const ringInsights = new RingInsightsService();

    const [loaderFlag, setLoaderFlag] = useState(true);
    const [data, setData] = useState<{ rows: IRow[]; columns: IColumn[] }>({
        rows: [],
        columns: []
    });

    const getRowsDefinition = (): IRow[] => {
        switch (props.client) {
            case 'Desktop':
                return rowsDefinition.filter((item) => !item.clientType.endsWith('web'));
            case 'WebClient':
                return rowsDefinition.filter((item) => item.clientType !== 'desktop');
            default:
                return rowsDefinition.filter((item) => !(item.clientType.startsWith('web') || item.clientType === 'desktop'));
        }
    };

    const getColumnsDefinition = (): IColumn[] => {
        let columnArray = columnsDefinition;
        if (props.os === 'Mac') {
            columnArray = columnArray.filter((item) => !item.name.startsWith('AG'));
        }
        if (props.client === 'ReactWebClient' || props.client === 'Maglev') {
            columnArray = columnArray.filter((item) => !item.name.endsWith('GCC'));
        }
        if (props.env === 'Life') {
            return columnArray.splice(0, 2);
        }
        return columnArray;
    };

    const gridStyles = () => {
        return {
            root: {},
            headerWrapper: {
                flex: '0 0 auto',
                '.ms-DetailsHeader': {
                    backgroundColor: 'lightgrey',
                    height: '27px',
                    lineHeight: '27px',
                    paddingTop: 0,
                    marginTop: 0
                }
            },
            contentWrapper: {
                flex: '1 1 auto',
                overflowY: 'auto',
                overflowX: 'hidden',
                '.ms-List-cell': {
                    minHeight: '20px'
                }
            }
        };
    };

    const formatDate = (date: string | undefined): string => {
        const trimmedData = date?.trim();
        if (!trimmedData || trimmedData.length === 0) {
            return '';
        }

        return new Date(trimmedData).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'short'
        });
    };

    useEffect(() => {
        const rows = getRowsDefinition();
        const columns = getColumnsDefinition();

        setLoaderFlag(true);

        const promises = columns.map(async (column) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const fieldName = column.fieldName!;
            if (fieldName === 'title') {
                return;
            }
            let respone;
            try {
                respone = await ringInsights.getRingInsights(props.client, props.os, props.env, 'None', column.name, props.ring);
            } catch (error) {
                console.error('Error fetching ring insights:', error);
                respone = null;
            }
            if (!respone) {
                rows.forEach((entry) => {
                    entry[fieldName] = 'N/A';
                });
            } else {
                rows[0][fieldName] = formatDate(respone.completedOn);
                rows[1][fieldName] = (
                    <a href={respone.buildUrl} target="_blank" rel="noreferrer">
                        {respone.buildNumber}
                    </a>
                );
                rows[2][fieldName] = (
                    <a href={respone.changesUrl} target="_blank" rel="noreferrer">
                        {respone.latestCommit}
                    </a>
                );
                rows[3][fieldName] = respone.ringBlockers ? (
                    <ul style={{ margin: 0, padding: 0 }}>
                        {respone.ringBlockers.map((x) => (
                            <li key={x.id}>
                                <a href={x.url} target="_blank" rel="noopener noreferrer">
                                    {x.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    'None'
                );
                if (props.client === 'Desktop') {
                    rows[4][fieldName] = respone.desktopData.teamsVersion.stable;
                    rows[5][fieldName] = respone.desktopData.teamsVersion.experimental;
                    rows[6][fieldName] = respone.desktopData.electronVersion.stable;
                    rows[7][fieldName] = respone.desktopData.electronVersion.experimental;
                    rows[8][fieldName] = respone.desktopData.slimcoreVersion.stable;
                    rows[9][fieldName] = respone.desktopData.slimcoreVersion.experimental;
                    rows[10][fieldName] = respone.desktopData.tsCallingVersion;
                } else if (props.client === 'WebClient') {
                    rows[4][fieldName] = respone.teamsVersion;
                    rows[5][fieldName] = (
                        <a href={respone.webClientData.orbitalFiles.buildUrl} target="_blank" rel="noreferrer">
                            {respone.webClientData.orbitalFiles.version}
                        </a>
                    );
                    rows[6][fieldName] = (
                        <a href={respone.webClientData.orbitalFiles.changesUrl} target="_blank" rel="noreferrer">
                            {respone.webClientData.orbitalFiles.commit}
                        </a>
                    );
                    rows[7][fieldName] = (
                        <a href={respone.webClientData.orbitalMultiWindow.buildUrl} target="_blank" rel="noreferrer">
                            {respone.webClientData.orbitalMultiWindow.version}
                        </a>
                    );
                    rows[8][fieldName] = (
                        <a href={respone.webClientData.orbitalMultiWindow.changesUrl} target="_blank" rel="noreferrer">
                            {respone.webClientData.orbitalMultiWindow.commit}
                        </a>
                    );
                } else {
                    rows[4][fieldName] = respone.teamsVersion;
                }
            }
        });

        Promise.all(promises).then(() => {
            setData((prevState) => ({ ...prevState, rows: rows, columns: columns }));
            setLoaderFlag(false);
        });
    }, [props.client, props.os, props.env, props.ring]);

    return (
        <>
            {loaderFlag && <Spinner size={SpinnerSize.large} label="Fetching records..." className="spinner-ring-insights" />}
            {!loaderFlag && (
                <Card styles={{ root: { marginBottom: '10px' }, header: { marginBottom: '5px', paddingLeft: '1px' } }}>
                    <CardHeader>{props.header}</CardHeader>
                    <DetailsList
                        items={data.rows}
                        columns={data.columns}
                        styles={gridStyles()}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => false}
                    />
                </Card>
            )}
        </>
    );
};

export default ClientSpecificInsights;
