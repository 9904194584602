import React from 'react';

import { configCategory, configCategoryDisplayName, userOverrideCategory, userOverrideCategoryDisplayName } from '../configs/defaults';

import Options from './Options';

type CategoryOptionsProps = {
    reset: boolean;
    update?: (category: string) => void;
    defaultValue?: string;
};

/**
 * Renders category options as a choice group and updates category options accordingly.
 *
 * @param props - The component props.
 * @returns The JSX element representing the category options choice group.
 */
const CategoryOptions: React.FC<CategoryOptionsProps> = (props) => {
    return (
        <Options
            {...props}
            defaultValue={props.defaultValue ?? configCategory}
            options={[
                { key: configCategory, text: configCategoryDisplayName },
                { key: userOverrideCategory, text: userOverrideCategoryDisplayName }
            ]}
        />
    );
};

export default CategoryOptions;
