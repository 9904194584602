import { Icon, initializeIcons } from '@fluentui/react';
import React from 'react';

import { PageUrl } from '../layout/configs/types';
import { ClientRepoMapPinnable } from '../services/models/ClientRepoMapPinnable';
import { PinnedTab } from '../services/models/PinnedTab';
import { updatePinnedTabs } from '../utils/clientUtility';

interface ClientTabWithPinProps {
    clientMap: ClientRepoMapPinnable;
    index: number;
    clientName: string | undefined;
    setTabData: React.Dispatch<React.SetStateAction<ClientRepoMapPinnable[]>>;
    pageUrl: PageUrl;
}

initializeIcons();

/**
 * @param props The props for the component.
 * @returns The client tab with pinned/ unpinned icon.
 */
const ClientTabWithPin: React.FC<ClientTabWithPinProps> = (props) => {
    const onClickIcon = (index: number) => {
        props.setTabData((prevTabData: ClientRepoMapPinnable[]) => {
            const updatedTabData = prevTabData.map((clientMap: ClientRepoMapPinnable, i) =>
                i === index ? { ...clientMap, isPinned: !clientMap.isPinned } : clientMap
            );

            updatedTabData.sort((firstClient, secondClient) => Number(secondClient.isPinned) - Number(firstClient.isPinned));

            const pinnedTabs: PinnedTab[] = updatedTabData
                .filter((tab) => tab.isPinned)
                .map((tab) => ({ client: tab.client, isPinned: tab.isPinned }) as PinnedTab);

            updatePinnedTabs(props.pageUrl, pinnedTabs);

            return updatedTabData;
        });
    };

    return (
        <span>
            {props.clientName}
            <Icon
                onClick={() => onClickIcon(props.index)}
                iconName={props.clientMap.isPinned ? 'PinnedSolid' : 'Pinned'}
                style={{ marginLeft: 8, fontSize: '20px' }}
            />
        </span>
    );
};

export default ClientTabWithPin;
