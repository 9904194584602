import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';

type OptionsProps = {
    reset: boolean;
    options: IChoiceGroupOption[];
    update?: (client: string) => void;
    defaultValue: string;
};

/**
 * Renders options as a choice group and updates the options accordingly.
 *
 * @param props - Component props.
 * @returns The JSX element representing the options choice group.
 */
const Options: React.FC<OptionsProps> = (props) => {
    // ================= State =================
    const disabled = props.update === undefined;

    const options: IChoiceGroupOption[] = useConst(props.options);

    const [value, setValue] = React.useState<string>(props.defaultValue);

    // ========================= Hooks =========================
    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.reset, disabled]);

    const onRadioChange = (option: IChoiceGroupOption) => {
        if (!props.update) return;
        if (option.key) {
            setValue(option.key.toString());
            props.update(option.key.toString());
            appInsightsClient.logEvent({ name: 'FFV2:StartFlight:RadioChange', properties: { option: option.key } });
        }
    };

    return (
        <ChoiceGroup
            options={options}
            required
            selectedKey={value}
            onChange={(_, val) => {
                if (val !== undefined) {
                    onRadioChange(val);
                }
            }}
            disabled={disabled}
        />
    );
};

export default Options;
