import FeatureFlagTrackingService from '../../../services/featureFlagTracking.service';
import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { SupportedFiltersCache, WorkItemCache, WorkItemsCache } from '../types/Types';

import { getCachedData, cacheData } from './FFv2Utils';

/**
 * Retrieves the supported filters from the cache or makes an API call to fetch them.
 *
 * @returns A promise that resolves to the supported filters.
 */
export async function getSupportedFilters() {
    const cachedData = (getCachedData('ffv2SupportedFilters') as SupportedFiltersCache)?.data;
    if (cachedData) {
        return cachedData;
    } else {
        return await new FeatureFlightService()
            .getSupportedFilters()
            .then((response) => {
                cacheData('ffv2SupportedFilters', response, 60);
                return response;
            })
            .catch((error) => {
                console.error(`Caught error fetching supported Filters, error: ${error}`);
                appInsightsClient.logException({ exception: error }, { message: 'Supported Filters Fetch Error' });
                throw error;
            });
    }
}

/**
 * Retrieves the supported user filters from the cache or makes an API call to fetch them.
 *
 * @returns A promise that resolves to the supported user filters.
 */
export async function getSupportedUserFilters() {
    return await new FeatureFlightService()
        .getSupportedUserFilters()
        .then((response) => {
            cacheData('ffv2SupportedUserFilters', response, 60);
            return response;
        })
        .catch((error) => {
            console.error(`Caught error fetching supported User Filters, error: ${error}`);
            appInsightsClient.logException({ exception: error }, { message: 'Supported User Filters Fetch Error' });
            throw error;
        });
}

/**
 * Retrieves the feature flags associated with a work item.
 *
 * @param workItemId - The ID of the work item.
 * @returns A promise that resolves to an array of feature flags.
 * @throws If there is an error fetching the work item or processing the data.
 */
export async function getWorkItem(workItemId: number): Promise<WorkItemCache> {
    const cachedData = (getCachedData('ffv2WorkItems') as WorkItemsCache)?.data ?? {};
    if (workItemId in cachedData) {
        return cachedData[workItemId];
    } else {
        return await new FeatureFlagTrackingService()
            .getDetailsByFeatureId(workItemId.toString())
            .then((workItems) => {
                const workItem = workItems.length > 0 ? workItems[0] : undefined;
                if (workItem) {
                    cacheData(
                        'ffv2WorkItems',
                        {
                            ...cachedData,
                            [workItemId]: { featureFlags: workItem.featureFlagList, areaPath: workItem.areaPath, state: workItem.state }
                        },
                        60
                    );
                    return {
                        featureFlags: workItem.featureFlagList,
                        areaPath: workItem.areaPath,
                        state: workItem.state
                    } as WorkItemCache;
                } else {
                    throw new Error('Work item not found');
                }
            })
            .catch((error) => {
                console.error(`Caught error fetching the ADO work item, error: ${error}`);
                appInsightsClient.logException({ exception: error }, { message: 'ADO work item Fetch Error' });
                throw error;
            });
    }
}
