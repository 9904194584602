// eslint-disable-next-line no-shadow
export enum PageUrl {
    Home = 'home',
    Shiproom = 'shiproom',
    Schedule = 'schedule',
    Retrospective = 'retrospective',
    RingInsights = 'ring-insights',
    Experiences = 'experiences',
    CommitTracking = 'commit-tracking',
    FeatureFlight = 'featureFlight',
    FeatureFlagReport = 'featureFlagReport',
    RecentChanges = 'recentChanges'
}
