import { IPivotItemProps, Pivot, PivotItem, Stack, initializeIcons } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClientRepoMap } from 'skynet-client';

import { Card, CardHeader } from '../../components/Card';
import ClientTabWithPin from '../../components/ClientTabWithPin';
import clientsJson from '../../configs/clients.base.json';
import { PageUrl } from '../../layout/configs/types';
import { ClientRepoMapPinnable } from '../../services/models/ClientRepoMapPinnable';
import { PinnedTab } from '../../services/models/PinnedTab';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getCachedPinnedTabsByPage, updateAndSortPinnedTabs } from '../../utils/clientUtility';

import { SupportedRings, SupportedTabs } from './configs/defaults';
import ShiproomClientReport from './ShiproomClientReport';
import { shiproomRootStackTokens, shiproomRootPivotCardStyles } from './styles/styles';

/**
 * Displays the shiproom page.
 *
 * @returns The main component for the page.
 */
const ShiproomPage: React.FC = () => {
    const [tabData, setTabData] = useState<ClientRepoMapPinnable[]>([]);
    const [selectedTabData, setSelectedTabData] = useState<ClientRepoMapPinnable>();

    const [searchParams, setSearchParams] = useSearchParams();

    initializeIcons();

    useEffect(() => {
        const clients: ClientRepoMapPinnable[] = clientsJson as ClientRepoMapPinnable[];

        const cachedPinnedTabs: PinnedTab[] = getCachedPinnedTabsByPage(PageUrl.Shiproom);
        const supportedTabs = clients.filter((clientMap: ClientRepoMap) => clientMap.client && SupportedTabs.includes(clientMap.client));

        // Update the isPinned property for each client in supportedTabs based on cachedPinnedTabs
        setTabData(updateAndSortPinnedTabs(supportedTabs, cachedPinnedTabs));

        const client = searchParams.get('client') ?? supportedTabs[0] ?? 'T2 Desktop Converged Win';

        if (selectedTabData === undefined && supportedTabs.length > 0) {
            const defaultTab = supportedTabs.find((tab) => tab.client === client) ?? supportedTabs[0];
            setSelectedTabData(defaultTab);
            appInsightsClient.logEvent({ name: 'Shiproom Report Tab View' }, { client: defaultTab.client });
        }
    }, []);

    return (
        <Stack tokens={shiproomRootStackTokens}>
            <Card styles={shiproomRootPivotCardStyles}>
                <CardHeader>Shiproom</CardHeader>
                {selectedTabData && (
                    <Pivot onLinkClick={onTabClick} selectedKey={selectedTabData.client} overflowBehavior="menu">
                        {tabData.map((clientMap: ClientRepoMapPinnable, index: number) => (
                            <PivotItem
                                itemKey={clientMap.client}
                                headerText={clientMap.client}
                                key={clientMap.client}
                                onRenderItemLink={(link?: IPivotItemProps) => (
                                    <ClientTabWithPin
                                        clientMap={clientMap}
                                        index={index}
                                        clientName={link?.headerText || ''}
                                        setTabData={setTabData}
                                        pageUrl={PageUrl.Shiproom}
                                    />
                                )}
                                headerButtonProps={{
                                    'data-id': `shiproom-report-client-pivot`,
                                    'data-value': clientMap.client ?? 'N/A'
                                }}
                            ></PivotItem>
                        ))}
                    </Pivot>
                )}
            </Card>
            {selectedTabData &&
                selectedTabData.clientType &&
                selectedTabData.os &&
                selectedTabData.environment &&
                selectedTabData.experience &&
                selectedTabData.client && (
                    <ShiproomClientReport
                        clientType={selectedTabData.clientType}
                        os={selectedTabData.os}
                        environment={selectedTabData.environment}
                        experience={selectedTabData.experience}
                        supportedRings={SupportedRings[selectedTabData.client]}
                    />
                )}
        </Stack>
    );

    function onTabClick(item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) {
        const key = item?.props.itemKey ?? 'Windows (T1)';
        const selectedTab = tabData.filter((tab) => tab.client === key)[0];
        setSelectedTabData(selectedTab);
        handleDeepLink(key);
        appInsightsClient.logEvent({ name: 'Shiproom Report Tab View' }, { client: selectedTab.client });
    }

    function handleDeepLink(key: string) {
        setSearchParams({ client: key });
    }
};

export default appInsightsClient.withAITracking(ShiproomPage, 'ShiproomReport');
