import {
    IButtonStyles,
    ICheckboxStyles,
    IComboBoxStyles,
    IDropdownStyles,
    IIconProps,
    IMessageBarStyles,
    IStackItemStyles,
    ITextFieldStyles,
    ITooltipHostStyles
} from '@fluentui/react';
import React, { CSSProperties } from 'react';

const createFlexStartItemStyle = (width: number, height: number): React.CSSProperties => {
    return {
        alignItems: 'flex-start',
        display: 'flex',
        height: height,
        justifyContent: 'flex-start',
        width: width
    };
};

export const itemStyleSmallWidth = createFlexStartItemStyle(130, 20);
export const itemStyleLargeWidth = createFlexStartItemStyle(170, 20);

export const buttonStyles: IButtonStyles = {
    root: {
        width: '160px',
        display: 'grid',
        alignItems: 'flex-start'
    }
};

export const messageBarStyles: IMessageBarStyles = {
    root: {
        alignItems: 'flex-start',
        marginLeft: '10px'
    }
};

export const stackItemStyles: IStackItemStyles = {
    root: {
        marginTop: '15px',
        marginBottom: '15px',
        alignItems: 'flex-start',
        display: 'grid',
        justifyContent: 'center',
        whiteSpace: 'nowrap'
    }
};

export const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        width: '200px'
    }
};

export const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
        width: '300px',
        maxWidth: 300
    }
};

export const dropdownStyles: Partial<IDropdownStyles> = {
    root: {
        width: '400',
        maxWidth: 400
    }
};

export const startFlightTableStyles = {
    borderRadius: '1em',
    paddingTop: '8px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '0px'
};

export const startFlightTableCellStyles = {
    padding: '10px'
};

export const startFlightCellWithTeachingBubbleStyles = {
    ...startFlightTableCellStyles,
    display: 'flex',
    alignItems: 'center'
};

export const formButtonStyles: IButtonStyles = {
    root: {
        width: '100px',
        height: '40px',
        alignItems: 'flex-start'
    }
};

export const formPrimaryButtonStyles: IButtonStyles = {
    root: {
        width: '200px',
        height: '40px',
        alignItems: 'flex-start'
    }
};

export const errorMessageStyle = {
    fontSize: '12px',
    fontWeight: 400,
    color: '#a4262c',
    marginTop: '8px'
};

export const existingRolloutAlertStyle: IMessageBarStyles = {
    root: {
        marginTop: '8px',
        padding: '8px'
    },
    innerText: {
        fontSize: '1.2em'
    }
};

export const errorCheckboxStyles: ICheckboxStyles = { checkbox: { borderWidth: '1.3px', borderStyle: 'solid', borderColor: '#a4262c' } };

export const tooltipStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
export const tooltipCalloutProps = { gapSpace: 0 };
export const removeIcon: IIconProps = { iconName: 'RemoveFilter' };

export const pairInputStyle = {
    root: {
        width: '160px'
    }
};

export const operatorInputStyle = {
    root: {
        width: '70px'
    }
};

export const tableStyles: CSSProperties = {
    borderCollapse: 'collapse',
    width: '100%'
};

export const cellStyles: CSSProperties = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    width: '200px'
};

export const platformCellStyles: CSSProperties = {
    ...cellStyles,
    width: '60px'
};

export const secondaryCheckBoxLabelStyle = {
    fontSize: '12px',
    fontWeight: 250,
    marginTop: '-10px',
    marginLeft: '28px'
};

export const secondaryCheckBoxWikiLabelStyle = {
    fontSize: '12px',
    fontWeight: 250,
    marginTop: '-10px'
};
