import { useMsal } from '@azure/msal-react';
import { INavLink } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { appInsightsClient } from '../utils/appInsightsUtility';

import { navLinkGroups } from './configs/defaults';
import { Header } from './Header';
import { LeftNav } from './LeftNav';
import { appStyles, contentContainerStyles } from './styles/styles';

/**
 * The main layout of the application.
 *
 * @returns The main layout of the application.
 */
export const AppLayout: React.FC = () => {
    const { accounts } = useMsal();
    const location = useLocation();
    const [isNavOpen, { setTrue: openNav, setFalse: dismissNav }] = useBoolean(true);
    const userEmail = accounts[0].username ?? 'unknown';
    appInsightsClient.setAuthenticatedUserContext(userEmail);

    const findNavLink = (
        parent: INavLink | undefined,
        navLinks: INavLink[],
        url: string
    ): { parent: INavLink | undefined; navLink: INavLink | undefined } => {
        for (const link of navLinks) {
            if (link.url === url) {
                return { parent, navLink: link };
            }
            if (link.links) {
                const foundLink = findNavLink(link, link.links, url);
                if (foundLink.navLink) {
                    return foundLink;
                }
            }
        }
        return { parent: undefined, navLink: undefined };
    };

    useEffect(() => {
        const pagePath = location.pathname;
        const { navLink } = findNavLink(undefined, navLinkGroups[0].links, pagePath);
        const documentTitle = navLink?.name ? `${navLink.name} - TES Reporting Portal` : 'TES Reporting Portal';
        document.title = documentTitle;
        appInsightsClient.trackPageView({ name: documentTitle, uri: pagePath });
    }, [location.pathname]);

    return (
        <div className="main-container" style={appStyles}>
            <Header toggleNav={() => (isNavOpen ? dismissNav() : openNav())} />
            <LeftNav isNavOpen={isNavOpen} />
            <Transition in={!isNavOpen} timeout={300}>
                {(state) => (
                    <div className="content-container" style={contentContainerStyles(state)}>
                        <Outlet />
                    </div>
                )}
            </Transition>
        </div>
    );
};
