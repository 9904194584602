import { Label, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

import { Card, CardHeader } from '../../../components/Card';
import ErrorNotification from '../../../components/ErrorNotification/ErrorNotification';
import { FetchIDDetailsResponse } from '../../../services/models/Ringmaster/RingmasterResponse';
import { ringmasterRequestTableCellStyle } from '../styles/RingmasterPageStyle';

type RingmasterFindIdResultProps = {
    isVisible: boolean;
    isDataLoaded: boolean;
    isLoading: boolean;
    issubmitErrorMsg: boolean;
    ringmasterFindIdInfoResponse: FetchIDDetailsResponse;
};

/**
 * A card to display the result of the FindId form.
 *
 * @param props - The props containing the visibility flag and the response data to display.
 * @returns The actual view.
 */
const RingmasterFindIdResult: React.FC<RingmasterFindIdResultProps> = (props) => {
    // ========================= Render =========================
    return (
        <>
            {props.isVisible && props.isLoading && <Spinner size={SpinnerSize.large} label="Loading data..." />}
            {props.isVisible && !props.isLoading && !props.isDataLoaded && !props.issubmitErrorMsg && (
                <ErrorNotification msg="Unable to fetch data" />
            )}
            {props.isVisible && !props.isLoading && props.isDataLoaded && (
                <Card>
                    <CardHeader>Results:</CardHeader>
                    <table>
                        <tbody>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Operation Id:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.ringmasterFindIdInfoResponse?.operationId}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Ring:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.ringmasterFindIdInfoResponse?.ring}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Cloud:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.ringmasterFindIdInfoResponse?.cloud}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Identity Type:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.ringmasterFindIdInfoResponse?.identityType}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Notes:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>{props.ringmasterFindIdInfoResponse?.notes}</td>
                            </tr>
                            <tr>
                                <td style={ringmasterRequestTableCellStyle}>
                                    <Label required>Is Strategic Tenant:</Label>
                                </td>
                                <td style={ringmasterRequestTableCellStyle}>
                                    {props.ringmasterFindIdInfoResponse?.isStrategicTenant ? 'Yes' : 'No'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            )}
        </>
    );
};

export default RingmasterFindIdResult;
