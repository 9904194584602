import { DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import React, { useEffect, useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { ProcessedFlightRollout } from '../types/Types';

type PauseFlightDialogProps = {
    isOpen: boolean;
    selectedFlight: ProcessedFlightRollout | undefined;
    onDismiss: () => void;
    updateFlight: (flightId: string) => void;
};

/**
 * Renders a dialog component for pausing a flight.
 *
 * @param {PauseFlightDialogProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const PauseFlightDialog: React.FC<PauseFlightDialogProps> = (props) => {
    const { isOpen, selectedFlight: flight, onDismiss, updateFlight } = props;
    const [manuallyPausedReason, setManuallyPausedReason] = useState<string>('');

    const dialogContentProps = {
        type: DialogType.normal,
        title: `Pause Flight ${flight?.id}`,
        closeButtonAriaLabel: 'Close',
        subText:
            'Pausing the flight will temporarily halt the ECS rollout. You can resume the rollout later. Are you sure you want to pause this flight?'
    };

    const handleConfirm = async () => {
        if (!flight || !manuallyPausedReason.trim()) {
            return;
        }
        await new FeatureFlightService().updateManuallyPaused(flight.id, true, manuallyPausedReason).then((_) => {
            updateFlight(flight.id);
            onDismiss();
        });
    };

    useEffect(() => {
        if (!isOpen) {
            setManuallyPausedReason('');
        }
    }, [isOpen]);

    return (
        <Dialog hidden={!isOpen || !flight} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            <TextField
                label="Reason for pausing the flight"
                multiline
                rows={3}
                value={manuallyPausedReason}
                onChange={(_, newValue) => setManuallyPausedReason(newValue || '')}
                required
            />
            <DialogFooter>
                <PrimaryButton onClick={handleConfirm} text="Pause" disabled={!manuallyPausedReason.trim()} />
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default PauseFlightDialog;
