import { ClientRepoMapPinnable } from '../services/models/ClientRepoMapPinnable';
import { PinnedTab } from '../services/models/PinnedTab';

/**
 * Transforms the client display name returned in the response to the latest convention.
 *
 * @param client The client.
 * @returns The transformed client display name.
 */
export function transformClientDisplayName(client: string) {
    switch (client) {
        case 'TFL Web (T2/RWC)':
            return 'T2 Web Consumer';
        case 'Windows (T2.1/Maglev)':
            return 'T2 Desktop Converged Win';
        case 'TFW Web (T2.1/RWC)':
            return 'T2 Web Enterprise';
        case 'Mac (T2.1/Maglev)':
            return 'T2 Desktop Converged Mac';
        default:
            return client;
    }
}

/**
 * Returns pinned tabs from local storage.
 *
 * @param page The page as string.
 * @returns The pinned tabs.
 */
export function getCachedPinnedTabsByPage(page: string): PinnedTab[] {
    const key = `${page}_PinnedTabs`;

    if (!localStorage.getItem(key)) {
        return [];
    } else {
        const pinnedTabsInLocalStorage = localStorage.getItem(key) || '[]';
        const pinnedTabs: PinnedTab[] = JSON.parse(pinnedTabsInLocalStorage);
        return pinnedTabs;
    }
}

/**
 * Updates the pinned tabs in local storage for the pages.
 *
 * @param page The page as string.
 * @param pinnedTabs The current pinned tabs for the client.
 */
export function updatePinnedTabs(page: string, pinnedTabs: PinnedTab[]) {
    const key = `${page}_PinnedTabs`;

    localStorage.setItem(key, JSON.stringify(pinnedTabs));
}

/**
 *
 * @param supportedTabs The supported tabs.
 * @param cachedPinnedTabs The cached pinned tabs for a specific page.
 *
 * @returns The updated and sorted pinned tabs by isPinned = true first.
 */
export function updateAndSortPinnedTabs(supportedTabs: ClientRepoMapPinnable[], cachedPinnedTabs: PinnedTab[]) {
    supportedTabs.forEach((client) => {
        client.isPinned = cachedPinnedTabs.some(
            (cachedPinnedTab: PinnedTab) => cachedPinnedTab.client === client.client && cachedPinnedTab.isPinned
        );
    });

    supportedTabs.sort((firstClient, secondClient) => Number(secondClient.isPinned) - Number(firstClient.isPinned));
    return supportedTabs;
}
