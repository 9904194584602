import { IconButton, Label, Modal, PrimaryButton, Stack, Text, Link, MessageBar, MessageBarType, ProgressIndicator } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { desktopTCNS, desktopVersion, experienceBuild } from '../configs/defaults';
import MinimumBuildVersionInput from '../FlightFormComponents/MinimumBuildVersionInput';
import { gapStackTokensMedium, gapStackTokensSmall, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { formStyles, iconButtonStyles } from '../styles/MyFlightsStyle';
import { UpdateMinBuildVersionFormData, ProcessedFlightRollout, FeatureFlightRollout, FilterOperation } from '../types/Types';
import { getDefaultEditFlightFormData, getMinimumBuildVersion, isValidClientVersion } from '../utilities/FFv2Utils';

type UpdateMinBuildVersionFormProps = {
    isOpen: boolean;
    onDismiss: () => void;
    selectedFlight: ProcessedFlightRollout | undefined;
    updateFlight: (flightId: string) => void;
};

/**
 * Displays the form to send requestUpdate.
 *
 * @param props The props for the component.
 * @returns The requestUpdate form.
 */
const UpdateMinBuildVersionForm: React.FC<UpdateMinBuildVersionFormProps> = (props) => {
    const { selectedFlight: flight, isOpen } = props;

    const [formData, setFormData] = useState<UpdateMinBuildVersionFormData>(getDefaultFormData());
    const [isSubmitEnabled, { setTrue: enableSubmit, setFalse: disableSubmit }] = useBoolean(false);
    const [editResult, setEditResult] = useState<FeatureFlightRollout | Error | 'loading' | undefined>();

    const titleId = useId('title');
    const cancelIcon = { iconName: 'Cancel' };

    return (
        <>
            {editResult &&
                editResult !== 'loading' &&
                ('message' in editResult ? (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        truncated={true}
                        onDismiss={() => setEditResult(undefined)}
                    >
                        <Text>Minimum build version update failed: {editResult.message}</Text>
                    </MessageBar>
                ) : (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        truncated={true}
                        onDismiss={() => setEditResult(undefined)}
                    >
                        <Text>
                            Minimum build version of flight{' '}
                            <ReactLink
                                to={'/featureFlight/' + editResult.id}
                                state={{ isOpenedFromMyFlights: true }}
                                target="_self"
                                style={{ textDecoration: 'none' }}
                            >
                                {editResult.id}
                            </ReactLink>{' '}
                            updated to {getMinimumBuildVersion(editResult.filters)}:
                            <Link href={editResult.ECSLink} target="_blank">
                                View in ECS
                            </Link>
                        </Text>
                    </MessageBar>
                ))}
            {editResult === 'loading' && <ProgressIndicator label={`Updating flight`} styles={progressIndicatorCustomStyles} />}
            <Modal
                className={formStyles.container}
                titleAriaId={titleId}
                isOpen={isOpen && !!flight}
                onDismiss={props.onDismiss}
                onDismissed={cleanup}
                isBlocking={false}
            >
                <div className={formStyles.header}>
                    <h3 id={titleId}>Update flight {flight?.id}</h3>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
                </div>
                <div className={formStyles.body}>
                    {!editResult && flight && (
                        <Stack tokens={gapStackTokensMedium}>
                            <form>
                                <Stack tokens={gapStackTokensSmall}>
                                    <Stack.Item>
                                        <Label>Client:</Label>
                                        <Text>{flight.client}</Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Label>Old Minimum Build Version:</Label>
                                        <Text>{flight.minimumBuildVersion}</Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Label required>New Minimum Build Version:</Label>
                                        <MinimumBuildVersionInput
                                            client={flight.client}
                                            update={onMinimumVersionInputChange}
                                            reset={false}
                                            showAllErrors={false}
                                            defaultValue={flight.minimumBuildVersion}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <br />
                                        <PrimaryButton text="Submit" onClick={onSubmit} allowDisabledFocus disabled={!isSubmitEnabled} />
                                    </Stack.Item>
                                </Stack>
                            </form>
                        </Stack>
                    )}
                </div>
            </Modal>
        </>
    );

    function onMinimumVersionInputChange(value: string) {
        if (!flight) return;
        setFormData({ version: value });
        if (value === flight.minimumBuildVersion || value.length === 0 || !isValidClientVersion(flight.client, value)) disableSubmit();
        else enableSubmit();
    }

    function onSubmit() {
        if (!flight) return;

        appInsightsClient.logEvent({ name: 'UpdateMinBuildVersionFormSubmit', properties: { response: 'good' } });
        setEditResult('loading');
        props.onDismiss();

        const editFormData = getDefaultEditFlightFormData(flight);
        const filterValue = { operation: FilterOperation.GreaterThanOrEqual, value: formData.version };

        if (flight.client === desktopTCNS) editFormData.filters[desktopVersion] = filterValue;
        else editFormData.filters[experienceBuild] = filterValue;

        new FeatureFlightService()
            .editRollout(flight.rolloutId, editFormData)
            .then((response) => {
                setEditResult(response);
                appInsightsClient.logEvent({ name: 'UpdateMinBuildVersionFormSubmit', properties: { response: response } });
                props.updateFlight(flight.id);
            })
            .catch((fail) => {
                setEditResult(fail);
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'caught error in processing block rollout in UpdateMinBuildVersionForm' }
                );
            });
        setFormData(getDefaultFormData());
        disableSubmit();
    }

    function cleanup() {
        setFormData(getDefaultFormData());
        disableSubmit();
    }

    function getDefaultFormData(): UpdateMinBuildVersionFormData {
        return {
            version: flight?.minimumBuildVersion ?? ''
        };
    }
};

export default UpdateMinBuildVersionForm;
