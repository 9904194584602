import {
    IconButton,
    Label,
    MessageBar,
    MessageBarType,
    Modal,
    ProgressIndicator,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
    Link
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState, useRef } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import ErrorNotification from '../../../components/ErrorNotification/ErrorNotification';
import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import {
    constantFilters,
    desktopTCNS,
    desktopVersion,
    experience,
    experienceBuild,
    skypeAClientPlatform,
    userOverrideCategory,
    webRWC
} from '../configs/defaults';
import AudienceCheckbox from '../FlightFormComponents/AudienceCheckbox';
import CategoryOptions from '../FlightFormComponents/CategoryOptions';
import ClientOptions from '../FlightFormComponents/ClientOptions';
import ExperienceDropdown from '../FlightFormComponents/ExperienceDropdown';
import FiltersInput from '../FlightFormComponents/FiltersInput';
import FlagsInput from '../FlightFormComponents/FlagsInput';
import FormButtons from '../FlightFormComponents/FormButtons';
import MinimumBuildVersionInput from '../FlightFormComponents/MinimumBuildVersionInput';
import PlatformsCheckbox from '../FlightFormComponents/PlatformsCheckbox';
import RolloutNameInput from '../FlightFormComponents/RolloutNameInput';
import { editFlightFormStyles, editFlightTableCellStyles } from '../styles/AdminFlightsStyle';
import { formStyles, iconButtonStyles, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { cancelIcon } from '../styles/MyFlightsStyle';
import { textFieldStyles } from '../styles/StartFlightFormStyle';
import { BaseFilters, EditFlightFormData, FilterOperation, FilterSchema, ProcessedFlightRollout } from '../types/Types';
import { getSupportedFilters, getSupportedUserFilters, getWorkItem } from '../utilities/FFv2DataUtils';
import {
    getDefaultEditFlightFormData,
    getExperience,
    getMinimumBuildVersion,
    getPlatforms,
    processFilters,
    processFlights
} from '../utilities/FFv2Utils';

type EditFlightFormProps = {
    isOpen: boolean;
    flight?: ProcessedFlightRollout;
    onDismiss: () => void;
    updateFlight: (flight: ProcessedFlightRollout) => void;
};

/**
 * EditFlightForm component.
 *
 * @param props - The props for the EditFlightForm component.
 * @returns {React.FC<EditFlightFormProps>} The EditFlightForm component.
 */
const EditFlightForm: React.FC<EditFlightFormProps> = (props) => {
    const { flight, isOpen, onDismiss } = props;
    const defaultEditFormData = getDefaultEditFlightFormData(flight);
    const client = flight?.client;
    const category = flight?.category;

    const [loadState, setLoadState] = useState<Error | 'loading' | undefined>();
    const [experienceOptions, setExperienceOptions] = useState<string[]>([]);
    const [supportedFilters, setSupportedFilters] = useState<FilterSchema[]>([]);
    const [supportedUserFilters, setSupportedUserFilters] = useState<FilterSchema[]>([]);

    const formData = useRef<EditFlightFormData>(defaultEditFormData);

    const [workItemFeatureFlags, setWorkItemFeatureFlags] = useState<string[]>([]);

    const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
    const [reset, { toggle: toggleReset }] = useBoolean(false);

    const [editResult, setEditResult] = useState<Error | ProcessedFlightRollout | 'loading' | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const optionalFilters = Object.entries(defaultEditFormData.filters)
        .map(([key, value]) => {
            return {
                name: key,
                value: value.value.toString(),
                operation: value.operation ?? FilterOperation.Equal
            };
        })
        .filter((filter) => {
            if (constantFilters.includes(filter.name)) return false;
            if (client === desktopTCNS && filter.name === desktopVersion) return false;
            if (client === webRWC && filter.name === experienceBuild) return false;
            return true;
        });

    const optionalUserFilters = defaultEditFormData.userFilters
        ? Object.entries(defaultEditFormData.userFilters).map(([name, value]) => ({
              name,
              value: value.toString(),
              operation: FilterOperation.Equal
          }))
        : [];

    useEffect(() => {
        if (!flight) return;

        setLoadState('loading');

        getSupportedFilters()
            .then((response) => {
                setSupportedFilters(processFilters(response));
                setExperienceOptions(response.Experience.keys.value.allow as string[]);

                setLoadState(undefined);
            })
            .catch((error) => {
                setLoadState(error);
            });

        getSupportedUserFilters()
            .then((response) => {
                setSupportedUserFilters(processFilters(response));

                setLoadState(undefined);
            })
            .catch((error) => {
                setLoadState(error);
            });

        getWorkItem(flight.boardingPassId)
            .then((workItem) => {
                if (workItem) {
                    setWorkItemFeatureFlags(workItem.featureFlags);
                } else {
                    setWorkItemFeatureFlags([]);
                }
                formData.current = getDefaultEditFlightFormData(flight);
                onReset();
                setLoadState(undefined);
            })
            .catch((error) => {
                setLoadState(error);
            });
    }, [flight]);

    const updateRolloutName = (name: string): void => {
        formData.current.rolloutName = name;
    };

    const updatePlatforms = (platforms: number[]) => {
        formData.current.filters[skypeAClientPlatform] = { operation: FilterOperation.Equal, value: platforms };
    };

    const updateExperience = (value?: string[]) => {
        if (value) {
            formData.current.filters[experience] = { operation: FilterOperation.Equal, value: value };
        } else {
            delete formData.current.filters[experience];
        }
    };

    const updateMinimumBuildVersion = (value: string) => {
        if (client === desktopTCNS) {
            formData.current.filters[desktopVersion] = { operation: FilterOperation.GreaterThanOrEqual, value: value };
        } else {
            formData.current.filters[experienceBuild] = { operation: FilterOperation.GreaterThanOrEqual, value: value };
        }
    };

    const addFilters = (filters: BaseFilters) => {
        formData.current.filters = {
            ...formData.current.filters,
            ...filters
        };
    };

    const deleteFilter = (filterKey: string) => {
        delete formData.current.filters[filterKey];
    };

    const addUserFilters = (userFilters: BaseFilters) => {
        formData.current.userFilters = {
            ...formData.current.userFilters,
            ...userFilters
        };
    };

    const deleteUserFilter = (filterKey: string) => {
        if (!formData.current.userFilters) {
            return;
        }

        delete formData.current.userFilters[filterKey];
    };

    const updateFeatureFlagsAndControlConfigs = (flagString?: string, controlString?: string) => {
        if (flagString) {
            formData.current.featureFlags = JSON.parse(flagString);
        }
        if (controlString) formData.current.controlConfigs = JSON.parse(controlString);
    };

    return (
        <>
            {typeof editResult === 'object' && 'message' in editResult && errorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    truncated={true}
                    onDismiss={() => setEditResult(undefined)}
                >
                    <Text>Editing failed: {errorMessage}</Text>
                </MessageBar>
            )}

            {typeof editResult === 'object' && 'id' in editResult && (
                <MessageBar messageBarType={MessageBarType.success} isMultiline={false} onDismiss={() => setEditResult(undefined)}>
                    <Text>
                        Rollout&nbsp;
                        <ReactLink
                            to={'/featureFlight/' + editResult.rolloutId}
                            state={{ isOpenedFromMyFlights: true }}
                            target="_self"
                            style={{ textDecoration: 'none' }}
                        >
                            <Text>
                                <Link>{editResult.rolloutId}</Link>
                            </Text>
                        </ReactLink>
                        &nbsp;has been edited.
                        <Link href={editResult.ECSLink} target="_blank">
                            View in ECS
                        </Link>
                    </Text>
                </MessageBar>
            )}
            {editResult === 'loading' && <ProgressIndicator label={`Updating flight`} styles={progressIndicatorCustomStyles} />}
            <Modal isOpen={!!isOpen && !!flight} onDismiss={onDismiss} isBlocking={false} className={formStyles.container}>
                <div className={formStyles.header}>
                    <h3>Editing rollout {flight?.id}</h3>
                    <IconButton styles={iconButtonStyles} iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={props.onDismiss} />
                </div>
                {loadState === 'loading' && (
                    <Spinner
                        size={SpinnerSize.medium}
                        label="Loading form data..."
                        styles={{
                            root: {
                                marginTop: '20px'
                            }
                        }}
                    />
                )}
                {typeof loadState === 'object' && <ErrorNotification msg="Unable to fetch filter and experience options for form" />}
                <div className={formStyles.body}>
                    {!loadState && supportedFilters && supportedUserFilters && workItemFeatureFlags && flight && client && (
                        <Stack styles={editFlightFormStyles}>
                            <Stack.Item>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Rollout Name:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <RolloutNameInput
                                                    update={updateRolloutName}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                    defaultValue={defaultEditFormData.rolloutName}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>ADO Work Item:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <TextField
                                                    styles={textFieldStyles}
                                                    value={flight.boardingPassId.toString()}
                                                    disabled
                                                    readOnly
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Client:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <ClientOptions reset={reset} defaultValue={client} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Category:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <CategoryOptions reset={reset} defaultValue={category} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Audience:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <AudienceCheckbox
                                                    reset={reset}
                                                    defaultValue={flight.audience}
                                                    showAllErrors={showAllErrors}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Platforms:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <PlatformsCheckbox
                                                    audience={flight.audience}
                                                    update={updatePlatforms}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                    defaultValue={getPlatforms(defaultEditFormData.filters)}
                                                />
                                            </td>
                                        </tr>

                                        {client === webRWC && (
                                            <tr>
                                                <td style={{ ...editFlightTableCellStyles, alignItems: 'center', display: 'flex' }}>
                                                    <Label required>Experience:</Label>
                                                </td>
                                                <td style={editFlightTableCellStyles}>
                                                    <ExperienceDropdown
                                                        isOpen={client === webRWC}
                                                        update={updateExperience}
                                                        reset={reset}
                                                        experienceOptions={experienceOptions}
                                                        defaultValue={getExperience(defaultEditFormData.filters)}
                                                        showAllErrors={showAllErrors}
                                                    />
                                                </td>
                                            </tr>
                                        )}

                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Minimum Build Version:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <MinimumBuildVersionInput
                                                    client={client}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                    update={updateMinimumBuildVersion}
                                                    defaultValue={getMinimumBuildVersion(defaultEditFormData.filters, client)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Filters:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <FiltersInput
                                                    kind="base"
                                                    client={client}
                                                    filterSchema={supportedFilters}
                                                    reset={reset}
                                                    addFilters={addFilters}
                                                    deleteFilter={deleteFilter}
                                                    defaultFilters={optionalFilters}
                                                />
                                            </td>
                                        </tr>
                                        {category === userOverrideCategory && (
                                            <tr>
                                                <td style={editFlightTableCellStyles}>
                                                    <Label>User Filters:</Label>
                                                </td>
                                                <td style={editFlightTableCellStyles}>
                                                    <FiltersInput
                                                        kind="user"
                                                        client={client}
                                                        filterSchema={supportedUserFilters}
                                                        reset={reset}
                                                        addFilters={addUserFilters}
                                                        deleteFilter={deleteUserFilter}
                                                        defaultFilters={optionalUserFilters}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td style={editFlightTableCellStyles}>
                                                <Label>Feature Flags:</Label>
                                            </td>
                                            <td style={editFlightTableCellStyles}>
                                                <FlagsInput
                                                    workItemFeatureFlags={workItemFeatureFlags}
                                                    reset={reset}
                                                    showAllErrors={showAllErrors}
                                                    update={updateFeatureFlagsAndControlConfigs}
                                                    defaultFlags={JSON.stringify(defaultEditFormData.featureFlags)}
                                                    defaultControlConfigs={JSON.stringify(defaultEditFormData.controlConfigs)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Stack.Item>
                            <br />
                            <Stack.Item>
                                <FormButtons
                                    formData={formData}
                                    audience={flight.audience}
                                    client={client}
                                    handleSubmit={onSubmit}
                                    handleReset={onReset}
                                    defaultValue={defaultEditFormData}
                                />
                            </Stack.Item>
                        </Stack>
                    )}
                </div>
            </Modal>
        </>
    );

    function onReset(): void {
        toggleReset();
        formData.current = defaultEditFormData;
        setShowAllErrors(false);
    }

    async function onSubmit(message: string): Promise<void> {
        if (!flight) return;

        setEditResult(undefined);

        if (message.length) {
            setShowAllErrors(true);
            return;
        }

        onDismiss();
        setEditResult('loading');
        for (const [key, value] of Object.entries(formData)) {
            if (JSON.stringify(defaultEditFormData[key as keyof EditFlightFormData]) === JSON.stringify(value)) {
                delete formData.current[key as keyof EditFlightFormData];
            }
        }

        await new FeatureFlightService()
            .editRollout(flight.rolloutId, formData.current)
            .then((response) => {
                appInsightsClient.logEvent({ name: 'FFV2:MyFlights:EditFormSubmit', properties: { response: response } });

                const updatedFlight = processFlights([response])[0];
                setEditResult(updatedFlight);
                props.updateFlight(updatedFlight);

                // Reset the form after successful submission
                onReset();
            })
            .catch((fail) => {
                const err = new Error(fail.errorDetail);
                setEditResult(err);
                if (fail instanceof Error) {
                    setErrorMessage(fail.message);
                }
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'catch error in processing editing rollout in EditFlightForm' }
                );
                console.error(`Caught error editing flight: ${flight.id}, error: ${fail}`);
            });
    }
};

export default EditFlightForm;
