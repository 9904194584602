import {
    Stack,
    ICommandBarItemProps,
    CommandBar,
    TeachingBubbleContent,
    IButtonProps,
    DirectionalHint,
    Coachmark,
    Toggle
} from '@fluentui/react';
import { useBoolean, useConst } from '@fluentui/react-hooks';
import React, { useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { getUserInfo } from '../../../utils/userUtility';
import { gapStackTokensSmall } from '../styles/FFv2Style';
import { commandBarStyles, customToggleStyles } from '../styles/FlightsCommandBarStyle';
import { cancelIcon } from '../styles/MyFlightsStyle';
import { FlightStatusFilter, ProcessedFlightRollout, UserInfo, FlightFilters } from '../types/Types';
import { isFlightInProgress } from '../utilities/FFv2Utils';

import ApprovalForm from './ApprovalForm';
import BlockFlightForm from './BlockFlightForm';
import CancelFlightDialog from './CancelFlightDialog';
import CompleteFlightDialog from './CompleteFlightDialog';
import FlightFiltersBar from './FlightFiltersBar';
import GeneralExceptionForm from './GeneralExceptionForm';
import PauseFlightDialog from './PauseFlightDialog';
import ResumeFlightDialog from './ResumeFlightDialog';
import UpdateMinBuildVersionForm from './UpdateMinBuildVersionForm';

type UserFlightsCommandBarProps = {
    flightFilters: FlightFilters;
    flightStages: string[];
    flightStatusFilter: FlightStatusFilter;
    getBlockers: (flightIds: number[]) => void;
    handleRefreshData: () => void;
    updateFlight: (flightId: string) => void;
    teamMembers: UserInfo[];
    otherUsers: UserInfo[];
    teachingBubbleStep: number;
    progressToNextStep: () => void;
    dismissTeachingBubble: () => void;
    selectedFlight?: ProcessedFlightRollout;
    handleFilterChange: (key: string, value: string | string[] | Date | undefined | boolean) => void;
    clearFilters: () => void;
    updateSubscribers: (flightId: string, isSubscribed: boolean) => void;
};

/**
 * MyFlightsCommandBar component.
 *
 * @param {UserFlightsCommandBarProps} props - The props for the component.
 * @returns {JSX.Element} The rendered MyFlightsCommandBar component.
 */
const UserFlightsCommandBar: React.FC<UserFlightsCommandBarProps> = (props) => {
    const { flightStatusFilter, selectedFlight, getBlockers, updateFlight } = props;
    const user = useConst(getUserInfo());
    const [approvalAction, setApprovalAction] = useState<string | undefined>();

    const [isBlockFlightModalOpen, { setTrue: showBlockFlightModal, setFalse: hideBlockFlightModal }] = useBoolean(false);
    const [isRequestUpdateModalOpen, { setTrue: showRequestUpdateModal, setFalse: hideRequestUpdateModal }] = useBoolean(false);
    const [isFilterOptionsOpen, { setTrue: showFilterOptions, setFalse: hideFilterOptions }] = useBoolean(true);
    const [isCompleteFlightDialogOpen, { setTrue: showCompleteFlightDialog, setFalse: hideCompleteFlightDialog }] = useBoolean(false);
    const [isCancelFlightDialogOpen, { setTrue: showCancelFlightDialog, setFalse: hideCancelFlightDialog }] = useBoolean(false);
    const [isPauseFlightDialogOpen, { setTrue: showPauseFlightDialog, setFalse: hidePauseFlightDialog }] = useBoolean(false);
    const [isResumeFlightDialogOpen, { setTrue: showResumeFlightDialog, setFalse: hideResumeFlightDialog }] = useBoolean(false);
    const [isExceptionFormOpen, { setTrue: openExceptionForm, setFalse: closeExceptionForm }] = useBoolean(false);

    const targetPivot = React.useRef<HTMLDivElement>(null);
    const primaryButtonProps: IButtonProps = {
        children: 'Next',
        onClick: props.progressToNextStep
    };

    const subscribe = () => {
        if (selectedFlight) {
            new FeatureFlightService().subscribe(selectedFlight.id).then(() => {
                props.updateSubscribers(selectedFlight.id, true);
            });
        }
    };

    const unsubscribe = () => {
        if (selectedFlight) {
            new FeatureFlightService().unsubscribe(selectedFlight.id).then(() => {
                props.updateSubscribers(selectedFlight.id, false);
            });
        }
    };

    const isFlightManuallyPaused = selectedFlight?.state?.isManuallyPaused;

    return (
        <>
            <BlockFlightForm
                isOpen={isBlockFlightModalOpen}
                onDismiss={hideBlockFlightModal}
                flight={selectedFlight}
                getBlockers={getBlockers}
            />
            <GeneralExceptionForm
                isOpen={isExceptionFormOpen}
                onDismiss={closeExceptionForm}
                flight={selectedFlight}
                updateFlight={updateFlight}
            />
            <ApprovalForm
                onDismiss={() => {
                    setApprovalAction(undefined);
                }}
                updateFlight={updateFlight}
                action={approvalAction}
                flight={selectedFlight}
            />
            <UpdateMinBuildVersionForm
                isOpen={isRequestUpdateModalOpen}
                onDismiss={hideRequestUpdateModal}
                selectedFlight={selectedFlight}
                updateFlight={updateFlight}
            />
            <CompleteFlightDialog
                isOpen={isCompleteFlightDialogOpen}
                onDismiss={hideCompleteFlightDialog}
                selectedFlight={selectedFlight}
                updateFlight={updateFlight}
            />
            <CancelFlightDialog
                isOpen={isCancelFlightDialogOpen}
                onDismiss={hideCancelFlightDialog}
                selectedFlight={selectedFlight}
                updateFlight={updateFlight}
            />
            <PauseFlightDialog
                isOpen={isPauseFlightDialogOpen}
                onDismiss={hidePauseFlightDialog}
                selectedFlight={selectedFlight}
                updateFlight={updateFlight}
            />
            <ResumeFlightDialog
                isOpen={isResumeFlightDialogOpen}
                onDismiss={hideResumeFlightDialog}
                selectedFlight={selectedFlight}
                updateFlight={updateFlight}
            />
            {props.teachingBubbleStep === 2 && (
                <Coachmark
                    target={targetPivot.current}
                    positioningContainerProps={{ directionalHint: DirectionalHint.bottomLeftEdge, doNotLayer: false }}
                >
                    <TeachingBubbleContent
                        headline="Flight operations"
                        hasCloseButton
                        closeButtonAriaLabel="Close"
                        onDismiss={props.dismissTeachingBubble}
                        primaryButtonProps={primaryButtonProps}
                        footerContent="2 of 4"
                    >
                        <li>Approve or reject flight in the &lsquo;pending approvals&rsquo; tab.</li>
                        <li>Update minimum build version or block flights in other tabs.</li>
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            {props.teachingBubbleStep === 3 && (
                <Coachmark
                    target={targetPivot.current}
                    positioningContainerProps={{ directionalHint: DirectionalHint.bottomRightEdge, doNotLayer: false }}
                >
                    <TeachingBubbleContent
                        headline="Display options"
                        hasCloseButton
                        closeButtonAriaLabel="Close"
                        onDismiss={props.dismissTeachingBubble}
                        primaryButtonProps={primaryButtonProps}
                        footerContent="3 of 4"
                    >
                        <li>Click on the column header to sort the table.</li>
                        <li>Utilize the icons on the right to refresh data or open filters.</li>
                    </TeachingBubbleContent>
                </Coachmark>
            )}
            <Stack tokens={gapStackTokensSmall}>
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
                    <CommandBar items={prepareCommandBarItems()} ariaLabel="Inbox actions" primaryGroupAriaLabel="Flight actions" />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', flexWrap: 'wrap' }}>
                        <Toggle
                            label="My Flights only"
                            inlineLabel
                            onChange={(_, checked) => props.handleFilterChange('showOnlyMyFlights', checked ?? false)}
                            styles={customToggleStyles}
                            defaultChecked={true}
                        />
                        <Toggle
                            label="Blocked only"
                            inlineLabel
                            onChange={(_, checked) => props.handleFilterChange('showOnlyBlocked', checked ?? false)}
                            styles={customToggleStyles}
                        />
                        <CommandBar
                            items={[]}
                            farItems={prepareCommandBarFarItems()}
                            ariaLabel="Filter actions"
                            farItemsGroupAriaLabel="Filter actions"
                            styles={commandBarStyles}
                        />
                    </div>
                </Stack>
                {isFilterOptionsOpen && (
                    <FlightFiltersBar
                        flightFilters={props.flightFilters}
                        flightStages={props.flightStages}
                        flightStatusFilter={flightStatusFilter}
                        handleFilterChange={props.handleFilterChange}
                        clearFilters={props.clearFilters}
                        selectedFlight={selectedFlight}
                        teamMembers={props.teamMembers}
                        otherUsers={!props.flightFilters.showOnlyMyFlights ? props.otherUsers : undefined}
                    />
                )}
            </Stack>
        </>
    );
    function prepareCommandBarItems(): ICommandBarItemProps[] {
        if (flightStatusFilter.key === 'pendingApproval') {
            return [
                {
                    key: 'approve',
                    text: 'Approve',
                    iconProps: { iconName: 'CheckMark' },
                    onClick: () => setApprovalAction('approved'),
                    disabled: selectedFlight === undefined || selectedFlight.createdBy.id === user.id
                },
                {
                    key: 'reject',
                    text: 'Reject',
                    iconProps: cancelIcon,
                    onClick: () => setApprovalAction('rejected'),
                    disabled: selectedFlight === undefined || selectedFlight.createdBy.id === user.id
                }
            ];
        } else if (['inProgress', 'pendingRequest'].includes(flightStatusFilter.key)) {
            const isSubscribed = selectedFlight?.isSubscribed;
            return [
                {
                    key: 'subscribe',
                    text: isSubscribed ? 'Unsubscribe' : 'Subscribe',
                    iconProps: { iconName: isSubscribed ? 'FavoriteStarFill' : 'FavoriteStar' },
                    onClick: isSubscribed ? unsubscribe : subscribe,
                    disabled: selectedFlight === undefined || selectedFlight?.createdBy.id === user.id
                },
                {
                    key: 'add',
                    text: 'Add',
                    iconProps: { iconName: 'Add' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'blocker',
                                text: 'Blocker',
                                iconProps: { iconName: 'BugSolid' },
                                onClick: showBlockFlightModal,
                                disabled: selectedFlight === undefined
                            },
                            {
                                key: 'exception',
                                text: 'Exception',
                                iconProps: { iconName: 'FileBug' },
                                onClick: openExceptionForm,
                                disabled: selectedFlight === undefined
                            }
                        ]
                    }
                },
                {
                    key: 'update',
                    text: 'Update',
                    iconProps: { iconName: 'Edit' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'minBuildVersion',
                                text: 'Minimum build version',
                                iconProps: { iconName: 'NumberSymbol' },
                                onClick: showRequestUpdateModal,
                                disabled: selectedFlight === undefined
                            }
                        ]
                    }
                },
                {
                    key: 'control',
                    text: 'Control',
                    iconProps: { iconName: 'Settings' },
                    subMenuProps: {
                        items: [
                            {
                                key: isFlightManuallyPaused ? 'resume' : 'pause',
                                text: isFlightManuallyPaused ? 'Resume' : 'Pause',
                                title:
                                    selectedFlight === undefined ||
                                    (selectedFlight.createdBy.id !== user.id &&
                                        !props.teamMembers.map((item) => item.id).includes(selectedFlight.createdBy.id) &&
                                        !selectedFlight.isSubscribed)
                                        ? 'You can only pause flights owned by your team or subscribed to'
                                        : 'Pause flight',
                                iconProps: { iconName: isFlightManuallyPaused ? 'Play' : 'Pause' },
                                onClick: () => (isFlightManuallyPaused ? showResumeFlightDialog() : showPauseFlightDialog()),
                                disabled:
                                    !selectedFlight ||
                                    !isFlightInProgress(selectedFlight) ||
                                    (selectedFlight.createdBy.id !== user.id &&
                                        !props.teamMembers.map((item) => item.id).includes(selectedFlight.createdBy.id) &&
                                        !selectedFlight.isSubscribed)
                            },
                            {
                                key: 'cancel',
                                text: 'Cancel',
                                title:
                                    selectedFlight === undefined ||
                                    (selectedFlight.createdBy.id !== user.id &&
                                        !props.teamMembers.map((item) => item.id).includes(selectedFlight.createdBy.id) &&
                                        !selectedFlight.isSubscribed) ||
                                    selectedFlight.state.ring !== 'ring0'
                                        ? 'You can only cancel ring 0 flights that are owned by your team or subscribed to'
                                        : 'Cancel flight',
                                iconProps: { iconName: 'Delete' },
                                onClick: () => showCancelFlightDialog(),
                                disabled:
                                    selectedFlight === undefined ||
                                    (selectedFlight.createdBy.id !== user.id &&
                                        !props.teamMembers.map((item) => item.id).includes(selectedFlight.createdBy.id) &&
                                        !selectedFlight.isSubscribed) ||
                                    selectedFlight.state.ring !== 'ring0'
                            }
                        ]
                    }
                }
            ];
        } else if (flightStatusFilter.key === 'readyToBurnIn') {
            return [
                {
                    key: 'complete',
                    text: 'Complete',
                    iconProps: { iconName: 'Completed' },
                    onClick: showCompleteFlightDialog,
                    disabled:
                        selectedFlight === undefined ||
                        (selectedFlight.createdBy.id !== user.id &&
                            !props.teamMembers.map((item) => item.id).includes(selectedFlight.createdBy.id) &&
                            !selectedFlight.isSubscribed)
                }
            ];
        } else {
            return [];
        }
    }

    function prepareCommandBarFarItems(): ICommandBarItemProps[] {
        return [
            {
                key: 'refresh',
                iconProps: { iconName: 'Refresh' },
                title: 'Refresh data (data is cached for 5 minutes)',
                onClick: props.handleRefreshData
            },
            {
                key: 'filter',
                iconProps: { iconName: isFilterOptionsOpen ? 'FilterSolid' : 'Filter' },
                title: isFilterOptionsOpen ? 'Hide filters' : 'Show filters',
                onClick: () => (isFilterOptionsOpen ? hideFilterOptions() : showFilterOptions())
            }
        ];
    }
};

export default UserFlightsCommandBar;
