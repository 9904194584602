import { Icon, Link, SearchBox, Spinner, SpinnerSize, Stack, Text, mergeStyles } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../components/Card';
import FeatureFlagTrackingService from '../../services/featureFlagTracking.service';
import { getADOWorkItemURL } from '../../utils/adoUtility';
import { appInsightsClient } from '../../utils/appInsightsUtility';

const FeatureFlagReportPage: React.FC = () => {
    const reportBaseLink =
        'https://msit.powerbi.com/reportEmbed?reportId=0d928935-4ad6-4dfd-9b55-d4a64f13e1c3&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&filterPaneEnabled=true&bookmarksPaneEnabled=true';
    const urlParams = new URLSearchParams(window.location.search);

    const featureId = urlParams.get('featureId');
    const rolloutId = urlParams.get('rolloutId');
    const [featureFlags, setFeatureFlags] = useState([] as string[]);
    const [featureTitle, setFeatureTitle] = useState<string>('');
    const [error, setError] = useState<Error | undefined>();

    const reportFullLink =
        reportBaseLink +
        (featureFlags.length === 0
            ? ''
            : `&pageName=ReportSection&filter=Flag_x0020_Ages/Flag_x0020_Name in ('${featureFlags.join('%27,%27')}')`) +
        (rolloutId ? `&pageName=ReportSection&filter=Client_x0020_Ecs_x0020_Config_x0020_Changes/Rollout_x0020_ID eq ${rolloutId}` : '');

    useEffect(() => {
        updateFeatureFlags();
    }, []);

    const updateFeatureFlags = () => {
        if (!featureId) {
            return;
        }
        if (isNaN(Number(featureId))) {
            setError(new Error('Invalid ADO Feature ID'));
            return;
        }
        new FeatureFlagTrackingService()
            .getDetailsByFeatureId(featureId)
            .then((response) => {
                setFeatureTitle(response[0].title);
                console.log(response[0]);
                if (
                    response[0].featureFlagList[0] === 'NO_FLAG' ||
                    response[0].featureFlagList[0] === 'MANUAL_OVERRIDE' ||
                    (response[0].featureFlagList.length === 1 && response[0].featureFlagList[0] === '')
                ) {
                    setFeatureFlags([]);
                }
                setFeatureFlags(response[0].featureFlagList);
            })
            .catch(() => {
                appInsightsClient.logException(
                    { exception: new Error('FeatureFlagReport:HandleFindAdoFeatureError') },
                    { message: 'Error occurred while trying to retrieve work item' }
                );
                setError(new Error('ADO Feature not found'));
            });
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Card>
                <CardHeader>Feature Flag Report</CardHeader>
                <Text variant="small">
                    Need help? Refer to{' '}
                    <Link href="https://aka.ms/FeatureFlagReportWiki" target="_blank" rel="noreferrer">
                        aka.ms/FeatureFlagReportWiki
                    </Link>{' '}
                    for using this report.
                </Text>
            </Card>
            <Card>
                <SearchBox
                    placeholder="ADO Feature ID"
                    onSearch={(newValue: string) => {
                        urlParams.set('featureId', newValue);
                        window.location.search = urlParams.toString();
                    }}
                />
                {featureId && (
                    <>
                        <br />
                        <Text variant="medium">
                            ADO Feature:{' '}
                            <Link href={getADOWorkItemURL(featureId)} target="_blank" rel="noreferrer noopener">
                                {featureTitle}
                            </Link>
                        </Text>
                        <br />
                        <Text>
                            If the rollout was started through TMP, you may be missing some flags due to mismatched flag names. You can fix
                            it by either of the following ways:
                            <ul>
                                <li>
                                    Click &apos;Flag Name&apos; under &apos;Filters on all pages&apos; and set filter type to &apos;Advanced
                                    filtering&apos;. Then, change &apos;is&apos; to &apos;contains&apos; for each flag.
                                </li>
                                <li>
                                    Update the feature flag names on the ADO work item to match the ECS config exactly and refresh this
                                    page.
                                </li>
                            </ul>
                            Start your rollout with{' '}
                            <Link href="/featureFlight" target="_blank">
                                Feature Flight
                            </Link>{' '}
                            to avoid this issue going forward!{' '}
                            <Link
                                href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/60342/Feature-Flighting-v2"
                                target="_blank"
                            >
                                See more details.
                            </Link>
                        </Text>
                    </>
                )}
            </Card>

            <Card>
                {featureId && featureTitle.length === 0 ? (
                    error ? (
                        <Stack
                            verticalAlign="center"
                            styles={{ root: { minHeight: '200px' } }}
                            tokens={{
                                childrenGap: 10
                            }}
                        >
                            <Stack.Item align="center">
                                <Icon
                                    iconName="AlertSolid"
                                    className={mergeStyles({
                                        fontSize: 50,
                                        height: 50,
                                        width: 50,
                                        margin: '0 25px'
                                    })}
                                />
                            </Stack.Item>
                            <Stack.Item align="center">
                                <Text variant="large">{error.message}</Text>
                            </Stack.Item>
                        </Stack>
                    ) : (
                        <Spinner size={SpinnerSize.large} label="Loading" />
                    )
                ) : (
                    <iframe
                        style={{ overflow: 'auto', minHeight: '85vh', minWidth: '100%' }}
                        title="Feature Flag Reporting"
                        src={reportFullLink}
                        allowFullScreen={true}
                    ></iframe>
                )}
            </Card>
        </Stack>
    );
};

export default appInsightsClient.withAITracking(FeatureFlagReportPage, 'FeatureFlagReportPage');
