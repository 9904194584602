import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import { FetchIDDetailsResponse } from '../../../services/models/Ringmaster/RingmasterResponse';
import RingmasterService from '../../../services/ringmaster.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { getUserInfo } from '../../../utils/userUtility';
import { defaultFindIdForm } from '../configs/defaults';
import { formButtonStyles, gapStackTokensMedium, stackItemStyles } from '../styles/RingmasterPageStyle';
import { FindIdFormData } from '../types/Types';

type FindIdFormButtonsProps = {
    formData: FindIdFormData;
    setFormData: React.Dispatch<React.SetStateAction<FindIdFormData>>;
    setResultCardData: React.Dispatch<React.SetStateAction<FetchIDDetailsResponse>>;
    setResultCardVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setResultCardIsDataLoaded: () => void;
    setResultCardIsDataNotLoaded: () => void;
    setResultCardIsLoading: () => void;
    setResultCardIsNotLoading: () => void;
    setResultCardIsSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
    resetData: boolean;
    resetFormDataTrigger: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Renders the primary action buttons for the FindId form.
 *
 * @param props - The props containing the form data to submit along with other helper functions and flags.
 * @returns The JSX element representing the primary action buttons for the form.
 */
const FindIdFormButtons: React.FC<FindIdFormButtonsProps> = (props) => {
    // ========================= State =========================
    const [submitErrorMsg, setSubmitErrorMsg] = useState<string>('');

    const ringmasterService = new RingmasterService();

    // ========================= Event handlers =========================
    const handleSubmit = async () => {
        setSubmitErrorMsg('');
        props.setResultCardVisibility(true);
        props.setResultCardIsLoading();
        props.setResultCardIsDataNotLoaded();
        props.setResultCardIsSubmitError(false);

        await ringmasterService
            .processFindIdInfoRequest(props.formData)
            .then((response) => {
                appInsightsClient.logEvent({
                    name: 'Ringmaster:FindIdForm:FindIdFormButtons:processFindIdInfoRequest',
                    properties: { response: response }
                });

                if (!response.error) {
                    props.setResultCardData(response);
                } else {
                    setSubmitErrorMsg('An error occurred during form submission. ' + response.error);
                    props.setResultCardIsSubmitError(true);
                }

                props.setResultCardIsDataLoaded();
            })
            .catch((error) => {
                console.error('Find Id Info request error', error);
                appInsightsClient.logException(
                    { exception: new Error('Ringmaster:FindIdForm:FindIdFormButtons:processFindIdInfoRequest') },
                    { message: 'Find Id Info request error' }
                );
                setSubmitErrorMsg('An error occurred during form submission. ' + error);
                props.setResultCardIsSubmitError(true);

                props.setResultCardIsDataNotLoaded();
            })
            .finally(() => {
                props.setResultCardIsNotLoading();
            });
    };

    const handleReset = () => {
        props.setFormData({ ...defaultFindIdForm, requestedBy: getUserInfo() });
        props.setResultCardVisibility(false);
        props.resetFormDataTrigger(!props.resetData);

        appInsightsClient.logEvent({ name: 'Ringmaster:FindIdForm:FindIdFormButtons:FormClear' });
    };

    const isFormDataValid = (): boolean => {
        const formData = props.formData as FindIdFormData;
        return formData.operationId.length !== 0;
    };

    // ========================= Render =========================
    return (
        <>
            <Stack>
                <Stack.Item>
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <Stack.Item styles={stackItemStyles}>
                            <PrimaryButton
                                text="Submit"
                                iconProps={{ iconName: 'Checkmark' }}
                                onClick={handleSubmit}
                                styles={formButtonStyles}
                                disabled={!isFormDataValid()}
                                allowDisabledFocus
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <DefaultButton
                                text="Reset"
                                iconProps={{ iconName: 'Clear' }}
                                onClick={handleReset}
                                styles={formButtonStyles}
                                allowDisabledFocus
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            {submitErrorMsg && (
                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} onDismiss={() => setSubmitErrorMsg('')}>
                    {submitErrorMsg}
                </MessageBar>
            )}
        </>
    );
};

export default FindIdFormButtons;
