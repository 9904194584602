import { Stack, Pivot, PivotItem, initializeIcons, IPivotItemProps } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import ClientTabWithPin from '../../components/ClientTabWithPin';
import { PageUrl } from '../../layout/configs/types';
import { ClientRepoMapPinnable } from '../../services/models/ClientRepoMapPinnable';
import { PinnedTab } from '../../services/models/PinnedTab';
import { getCachedPinnedTabsByPage, updateAndSortPinnedTabs } from '../../utils/clientUtility';

import ClientSpecificInsights from './ClientSpecificInsights';
import { clientsDefinition } from './types';

import './RingInsights.css';

/**
 * Renders the RingInsights component.
 *
 * @returns {React.FC} The rendered RingInsights componfent.
 */
const RingInsights: React.FC = () => {
    const [tabData, setTabData] = useState<ClientRepoMapPinnable[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>();

    initializeIcons();

    useEffect(() => {
        const cachedPinnedTabs: PinnedTab[] = getCachedPinnedTabsByPage(PageUrl.RingInsights);

        const supportedTabs = clientsDefinition as ClientRepoMapPinnable[];

        // Update the isPinned property for each client in supportedTabs based on cachedPinnedTabs;
        setTabData(updateAndSortPinnedTabs(supportedTabs, cachedPinnedTabs));

        setSelectedTab(supportedTabs[0]?.client || clientsDefinition[0].client || '');
    }, []);

    const onTabClick = (item?: PivotItem, _ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (item?.props.itemKey) {
            setSelectedTab(item.props.itemKey);
        }
    };

    return (
        <div className="ring">
            <Stack className="ring-insights-top">
                <Stack>
                    <span className="header-title">Ring Insights</span>
                </Stack>
            </Stack>
            <Pivot onLinkClick={onTabClick} selectedKey={selectedTab} className="client-tabs">
                {tabData?.map((client: ClientRepoMapPinnable, index: number) => (
                    <PivotItem
                        itemKey={client.client}
                        headerText={client.client}
                        key={client.client}
                        onRenderItemLink={(link?: IPivotItemProps) => (
                            <ClientTabWithPin
                                clientMap={client}
                                index={index}
                                clientName={link?.headerText || ''}
                                setTabData={setTabData}
                                pageUrl={PageUrl.RingInsights}
                            />
                        )}
                        className="pivot-item"
                    >
                        <ClientSpecificInsights
                            client={client.clientType || ''}
                            os={client.os || ''}
                            env={client.environment || ''}
                            ring="Ring0"
                            header="Ring 0"
                        />
                        <ClientSpecificInsights
                            client={client.clientType || ''}
                            os={client.os || ''}
                            env={client.environment || ''}
                            ring="Ring1"
                            header="Ring 1"
                        />
                    </PivotItem>
                ))}
            </Pivot>
        </div>
    );
};

export default RingInsights;
