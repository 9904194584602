import { ITextField, Link, MessageBar, MessageBarType, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import React, { createRef, useEffect, useState } from 'react';

import FeatureFlagTrackingService from '../../../services/featureFlagTracking.service';
import { getADOWorkItemURL, isValidWorkItemId } from '../../../utils/adoUtility';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { gapStackTokensMedium } from '../styles/FFv2Style';
import { messageBarStyles, textFieldStyles } from '../styles/StartFlightFormStyle';
import { InvalidADOItemState, InvalidADOItemNoFeatureFlags, ADOItemNotExist, FailToRetrieveWorkItem } from '../utilities/FFv2Errors';

type AdoWorkItemInputProps = {
    reset: boolean;
    showAllErrors: boolean;
    update: (workItemId: string, flags: string[]) => void;
};

/**
 * Renders an input section for entering and searching ADO work items related to feature flags.
 *
 * @param props - The props containing adoWorkItem, setAdoWorkItem, workItemDetails, setWorkItemDetails, isWorkItemFound, setIsWorkItemFound, workItemValidationMsg, setWorkItemValidationMsg, formData, and setFormData.
 * @returns The JSX element representing the ADO work item input section.
 */
const AdoWorkItemInput: React.FC<AdoWorkItemInputProps> = (props) => {
    // ================= State =================
    const { reset, showAllErrors } = props;

    const [workItemId, setWorkItemId] = useState<string>('');
    const [workItemTitle, setWorkItemTitle] = useState<string | undefined>();
    const workItemUrl = getADOWorkItemURL(workItemId);

    const [workItemState, setWorkItemState] = useState<Error | 'loading' | undefined>();

    const textFieldRef = createRef<ITextField>();

    // ========================= Hooks =========================
    useEffect(() => {
        setWorkItemId('');
        setWorkItemTitle(undefined);
        setWorkItemState(undefined);
    }, [reset]);

    useEffect(() => {
        if (showAllErrors && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [showAllErrors]);

    const onChangeInput = (value: string) => {
        setWorkItemId(value);
        setWorkItemTitle(undefined);
        setWorkItemState(undefined);
        props.update('', []);
    };

    const handleFindMyADOFeature = () => {
        setWorkItemTitle(undefined);
        setWorkItemState('loading');

        new FeatureFlagTrackingService()
            .getDetailsByFeatureId(workItemId)
            .then((response) => {
                if (response.length === 0) {
                    // Handle case when item is not found
                    props.update('', []);
                    const notExistError = new ADOItemNotExist();
                    setWorkItemState(notExistError);
                    appInsightsClient.logException({ exception: notExistError }, { message: notExistError.message });
                } else {
                    // Handle case when item is found
                    setWorkItemTitle(response[0].title);
                    appInsightsClient.logEvent(
                        { name: 'FFV2:StartFlight:HandleFindMyAdoFeature' },
                        { workItemTitle: workItemTitle, workItemId: workItemId, featureFlags: response[0].featureFlagList }
                    );

                    let validationError: Error | undefined;
                    if (response[0].state !== 'RollingOut' && response[0].state !== 'Active') {
                        validationError = new InvalidADOItemState();
                    } else if (
                        response[0].featureFlagList[0] === 'NO_FLAG' ||
                        response[0].featureFlagList[0] === 'MANUAL_OVERRIDE' ||
                        (response[0].featureFlagList.length === 1 && response[0].featureFlagList[0] === '')
                    ) {
                        validationError = new InvalidADOItemNoFeatureFlags();
                    }

                    if (validationError) {
                        appInsightsClient.logException({ exception: validationError }, { message: validationError.message });
                    } else {
                        props.update(workItemId, response[0].featureFlagList);
                    }
                    setWorkItemState(validationError);
                }
            })
            .catch((fail) => {
                props.update('', []);
                setWorkItemState(new FailToRetrieveWorkItem(`Error occurred while trying to retrieve work item: ${fail}`));
                appInsightsClient.logException(
                    { exception: new Error('FFV2:StartFlight:HandleFindMyAdoFeatureError') },
                    { message: 'Error occurred while trying to retrieve work item' }
                );
            });
    };

    function getWorkItemIdErrorMessage(value: string): string {
        if (value && isValidWorkItemId(value)) {
            if (!showAllErrors) {
                handleFindMyADOFeature();
            }
            return '';
        }

        if (!value) {
            return 'Input the ID of an ADO Feature in "Rolling Out" or "Active" state with at least one feature flag';
        }

        return 'Invalid work item ID';
    }

    function getWorkItemErrorMessageBar() {
        if (workItemState instanceof InvalidADOItemState) {
            return (
                <>
                    Invalid state of
                    <Link href={workItemUrl} target="_blank">
                        {workItemTitle}
                    </Link>
                    : Please set its state to &quot;Rolling Out&quot; and try again.
                </>
            );
        } else if (workItemState instanceof InvalidADOItemNoFeatureFlags) {
            return (
                <>
                    No feature flag in
                    <Link href={workItemUrl} target="_blank">
                        {workItemTitle}
                    </Link>
                    : Please add at least one feature flag and try again.
                </>
            );
        }
    }

    // ========================= Render =========================
    return (
        <Stack horizontal tokens={gapStackTokensMedium}>
            <Stack.Item>
                <TextField
                    styles={textFieldStyles}
                    id="boardingPassId"
                    placeholder="ADO Feature work item ID"
                    value={workItemId}
                    componentRef={textFieldRef}
                    validateOnLoad={false}
                    validateOnFocusIn
                    validateOnFocusOut
                    deferredValidationTime={500}
                    onGetErrorMessage={getWorkItemIdErrorMessage}
                    onChange={(event, value) => {
                        if (value !== undefined) {
                            onChangeInput(value);
                        }
                    }}
                />
            </Stack.Item>
            <Stack.Item>
                {workItemState === 'loading' && <Spinner size={SpinnerSize.medium} label="Fetching work item..." labelPosition="right" />}
                {workItemState && workItemState instanceof FailToRetrieveWorkItem && (
                    <MessageBar delayedRender={false} messageBarType={MessageBarType.error} styles={messageBarStyles}>
                        {workItemState.message}
                    </MessageBar>
                )}
                {workItemTitle &&
                    workItemTitle !== 'loading' &&
                    (!workItemState ? (
                        <MessageBar delayedRender={false} messageBarType={MessageBarType.success} styles={messageBarStyles}>
                            <Link href={workItemUrl} target="_blank">
                                {workItemTitle}
                            </Link>
                        </MessageBar>
                    ) : (
                        <MessageBar delayedRender={false} messageBarType={MessageBarType.error} styles={messageBarStyles}>
                            {getWorkItemErrorMessageBar()}
                        </MessageBar>
                    ))}
            </Stack.Item>
        </Stack>
    );
};

export default AdoWorkItemInput;
