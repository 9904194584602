export const SupportedTabs = [
    'Mac (T1)',
    'Windows (T1)',
    'TFW Web (T1)',
    'TFL Web (T1)',
    'Windows (T2/Maglev)',
    'T2 Web Consumer',
    'T2 Desktop Converged Win',
    'T2 Web Enterprise',
    'T2 Desktop Converged Mac',
    'Mobile (Android)',
    'Mobile (IOS)'
];

export const SupportedRings: Record<string, Record<string, string[]>> = {
    'Mac (T1)': {
        Prod: ['General'],
        GCC: ['General'],
        GCCH: ['General'],
        DoD: ['General']
    },
    'Windows (T1)': {
        Prod: ['General'],
        Gallatin: ['General'],
        GCC: ['General'],
        GCCH: ['General'],
        DoD: ['General'],
        AG08: ['General'],
        AG09: ['General']
    },
    'TFW Web (T1)': {
        Prod: ['General'],
        Gallatin: ['General'],
        GCC: ['General'],
        GCCH: ['General'],
        DoD: ['General'],
        AG08: ['General'],
        AG09: ['General']
    },
    'TFL Web (T1)': {
        Prod: ['General']
    },
    'Windows (T2/Maglev)': {
        Prod: ['Ring2', 'Ring3', 'General']
    },
    'T2 Web Consumer': {
        Prod: ['Ring2', 'Ring3', 'General']
    },
    'T2 Desktop Converged Win': {
        Prod: ['Ring2', 'Ring3', 'General'],
        Gallatin: ['General'],
        GCCH: ['General'],
        DoD: ['General'],
        AG08: ['General'],
        AG09: ['General']
    },
    'T2 Web Enterprise': {
        Prod: ['Ring2', 'Ring3', 'General'],
        Gallatin: ['General'],
        GCCH: ['General'],
        DoD: ['General'],
        AG08: ['General'],
        AG09: ['General']
    },
    'T2 Desktop Converged Mac': {
        Prod: ['Ring2', 'Ring3', 'General'],
        Gallatin: ['General'],
        GCCH: ['General'],
        DoD: ['General'],
        AG08: ['General'],
        AG09: ['General']
    },
    'Mobile (Android)': {
        Prod: ['Ring1', 'Ring2', 'Ring3', 'General']
    },
    'Mobile (IOS)': {
        Prod: ['Ring1', 'Ring2', 'Ring3', 'General']
    }
};

export const releaseManagers = [
    'dballesteros',
    'roahs',
    'shylaw',
    'amystoenoiu',
    'mitraghaderi',
    'jimmul',
    'ptripathi',
    'samroth',
    'nareddy',
    'rikashya',
    'agarwalsh',
    'nagamani.reddy',
    'ambikakhare'
];
