import { Checkbox, Link, Stack, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';

import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { audienceNames, audiencePlatformsMap, featureReadinessWikiLink, platformsMap } from '../configs/defaults';
import { stackTokens } from '../styles/FFv2Style';
import {
    errorCheckboxStyles,
    errorMessageStyle,
    itemStyleSmallWidth,
    secondaryCheckBoxLabelStyle,
    secondaryCheckBoxWikiLabelStyle
} from '../styles/StartFlightFormStyle';

type PlatformsCheckboxProps = {
    audience: string[] | string;
    reset: boolean;
    showAllErrors: boolean;
    update: (platform: number[]) => void;
    defaultValue?: number[];
};

/**
 * Renders a set of checkboxes for selecting platforms.
 *
 * @param props - The props containing platforms, setPlatforms, and setFormData.
 * @returns The JSX element representing the platforms checkboxes.
 */
const PlatformsCheckbox: React.FC<PlatformsCheckboxProps> = (props) => {
    // ========================= State =========================
    const { audience, reset, showAllErrors } = props;
    const defaultValue = props.defaultValue ?? [];
    const [platforms, setPlatforms] = React.useState<number[]>(defaultValue);

    const checkBoxStyles = !platforms.length && showAllErrors ? errorCheckboxStyles : {};

    const platformAttributes = {
        web1415: {
            tooltipHostContent: 'Applies to T2.0 (TFL) and T2.1 (TFW)',
            label: 'Web (1415)'
        },
        maglev48: {
            tooltipHostContent: 'Applies to T2.0 (TFL) Windows 11 only. This client already migrated to 49',
            label: 'Maglev (48)',
            secondaryLabel: '48 is now migrated to 49. Select this option only if 48 readiness is complete: See '
        },
        maglev49: {
            tooltipHostContent: 'Applies to T2.1 (TFW) and T2.2 Converged Client (TFL) Windows only',
            label: 'Maglev (49)'
        },
        maglevMac50: {
            tooltipHostContent: 'Applies to T2.1 (TFW) and T2.2 Converged Mac Client (TFL) Mac only',
            label: 'Maglev Mac (50)'
        }
    };

    // ========================= Hooks =========================
    useEffect(() => {
        setPlatforms(defaultValue);
    }, [reset]);

    useEffect(() => {
        if (Array.isArray(audience)) {
            const updatedPlatforms = audience.includes(audienceNames.consumer)
                ? audiencePlatformsMap[audienceNames.consumer]
                : platforms.filter((platform) => {
                      return audience.some((aud) => audiencePlatformsMap[aud]?.includes(platform));
                  });
            setPlatforms(updatedPlatforms);
        }
    }, [audience]);

    // ========================= Event handlers =========================
    const handlePlatformsCheckboxChange = (value: number, isChecked: boolean) => {
        let updatePlatforms: number[] = [];
        if (isChecked) {
            updatePlatforms = [...platforms, value];
            appInsightsClient.logEvent({
                name: 'FFV2:StartFlight:PlatformsCheckboxChange',
                properties: { value: value, isChecked: isChecked }
            });
        } else {
            updatePlatforms = platforms.filter((platform) => platform !== value);
            appInsightsClient.logEvent({
                name: 'FFV2:StartFlight:PlatformsCheckboxChange',
                properties: { value: value, isChecked: isChecked }
            });
        }

        setPlatforms(updatePlatforms);
        props.update(updatePlatforms);
    };

    // ========================= Render =========================
    return (
        <Stack tokens={stackTokens}>
            <Stack horizontal>
                <Stack.Item style={itemStyleSmallWidth}>
                    <TooltipHost content={platformAttributes.web1415.tooltipHostContent}>
                        <Checkbox
                            label={platformAttributes.web1415.label}
                            checked={platforms.includes(platformsMap.webT2Platform)}
                            onChange={(_, isChecked) => {
                                if (isChecked !== undefined) {
                                    handlePlatformsCheckboxChange(platformsMap.webT2Platform, isChecked);
                                }
                            }}
                            styles={checkBoxStyles}
                        />
                    </TooltipHost>
                </Stack.Item>

                <Stack.Item style={itemStyleSmallWidth}>
                    <TooltipHost content={platformAttributes.maglev49.tooltipHostContent}>
                        <Checkbox
                            label={platformAttributes.maglev49.label}
                            checked={platforms.includes(platformsMap.desktopT21Platform)}
                            onChange={(_, isChecked) => {
                                if (isChecked !== undefined) {
                                    handlePlatformsCheckboxChange(platformsMap.desktopT21Platform, isChecked);
                                }
                            }}
                            styles={checkBoxStyles}
                        />
                    </TooltipHost>
                </Stack.Item>

                <Stack.Item>
                    <TooltipHost content={platformAttributes.maglevMac50.tooltipHostContent}>
                        <Checkbox
                            label={platformAttributes.maglevMac50.label}
                            checked={platforms.includes(platformsMap.desktopMacT21Platform)}
                            onChange={(_, isChecked) => {
                                if (isChecked !== undefined) {
                                    handlePlatformsCheckboxChange(platformsMap.desktopMacT21Platform, isChecked);
                                }
                            }}
                            styles={checkBoxStyles}
                        />
                    </TooltipHost>
                </Stack.Item>
            </Stack>

            <Stack horizontal>
                <Stack.Item>
                    <TooltipHost content={platformAttributes.maglev48.tooltipHostContent}>
                        <Checkbox
                            label={platformAttributes.maglev48.label}
                            checked={platforms.includes(platformsMap.desktopT20Platform)}
                            disabled={!audience.includes(audienceNames.consumer)}
                            onChange={(_, isChecked) => {
                                if (isChecked !== undefined) {
                                    handlePlatformsCheckboxChange(platformsMap.desktopT20Platform, isChecked);
                                }
                            }}
                            styles={checkBoxStyles}
                        />
                    </TooltipHost>
                </Stack.Item>
            </Stack>

            <Stack horizontal>
                <Stack.Item>
                    <div style={secondaryCheckBoxLabelStyle}>
                        {platformAttributes.maglev48.secondaryLabel}
                        <Link target="_blank" style={secondaryCheckBoxWikiLabelStyle} href={featureReadinessWikiLink}>
                            Wiki
                        </Link>
                        :
                    </div>
                </Stack.Item>
            </Stack>

            {!platforms.length && showAllErrors && (
                <div role="alert" style={errorMessageStyle}>
                    Select at least one platform
                </div>
            )}
        </Stack>
    );
};

export default PlatformsCheckbox;
